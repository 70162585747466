<script>
import {isProxy, toRaw} from 'vue';
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import {router} from '../helpers/router';
import {utilsMixin} from '../helpers/utils';
import ProjectLibrary from "./Components/ProjectLibrary.vue";
import TheMenu from "./TheMenu.vue"
import ManageFiles from "./Components/ManageFiles.vue"
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {useWizardStore} from '../stores/wizard.store';

export default {
  props: ['editID'],
  mixins: [utilsMixin],
  data() {
    return {
      artSpecLink: "",
      baseUrl: process.env.APIURL,
      TheName: "TheVuePortal",
      franchise: process.env.Franchise,
      wizardStore: useWizardStore(),
      UI: {
        acmLayout: {
          holeSizeError: false,
          panelWidth: 0,
          panelHeight: 0,
          panelMaxWidth: 0,
          panelMaxHeight: 0,
          SheetWidth: 0,
          SheetHeight: 0,
          currentPreviewSheet: 0,
          previewSheetCount: 0,
          minsize: 4,
          panelImageCount: 0,
        },
        // hide window decoration and magnetic for now.  Magnetic also being hidden some other way, not sure how..
        // allowedProductTypes: ["adhesive", "rigid", "Wallcoverings", "Window Decoration", "Magnetic"],
        allowedProductTypes: ["adhesive", "rigid", "Wallcoverings"],
        applyAllOptions: {
          adhesive: ["Button", "ItemNo", "LaminateType"],
          rigid: [
            "Button",
            "round_corners",
            "holes",
            "hole_placement",
            "hole_edge_distance",
            "hole_hole_distance",
            "grommets",
            "self_cut",
            "InchWidth",
            "InchHeight"
          ],
          misc: ["Button", "ItemNo"],
        },
        svgPreview: {
          holes: [],
          holeSizeError: false,
          cToCenter: 2,
          cToEdge: 1,
          hDiameter: .375,
          width: 1,
          height: 1,
          cornerRadius: 0.0,
          panelImages: [],
          panelXCount: 0,
          panelYCount: 0,
          sheetSize: "width: 40vh; height: 20vh",
        },
        layoutPreview: {
          showProductPreview: false,
          showSheetPreview: false,
          widthInInches: 0,
          heightInInches: 0,
        },
        libraryActive: false,
        deleteImageName: "",
        lockedOptions: {
          rigid: ["Message", "rigid_type", "ItemNo", "MaterialSheetSize", "SheetWidth", "SheetHeight", "size", "sides", "LaminateType", "Rotate", "InchWidth", "InchHeight"],
        },
        productIcons: [
          { ChoiceValue: "adhesive", IconFile: "icon-adhesive.svg" },
          { ChoiceValue: "Banner", IconFile: "icon-banner.svg" },
          { ChoiceValue: "rigid", IconFile: "icon-rigid.svg" },
          { ChoiceValue: "Wallcoverings", IconFile: "icon-wallcoverings.svg", },
          { ChoiceValue: "Window Decoration", IconFile: "icon-window-decoration.svg" },
          { ChoiceValue: "Magnetic", IconFile: "icon-magnetic.svg" }
        ],
        Overlap: false,
        showInstructionPane: true,
        showHideSpeed: 300,
        showNoImagePrice: true,
        showRestartPortal: false,
      },
      product_type: "",
      material_type: "",
      corexFound: false,
      addedImage: "",
      configData: [],
      cart: {
        updateCart: false,
        cartText: "Item(s) added to cart.",
        cartButtonText: "Add Image First",
        stopCartSubmit: true,
        showCartModal: false,
        showCartAnyway: false,
        continueCreating: false,
        errors: "",
      },
      activeImage: {
        Id: '',
        errorList: [],
        warningList: [],
      },
      checkPricingData: {},
      errorTypes: [
        {
          name: "ACMOversized",
          text: "- Image is beyond the panel size and will be resized to fit.",
        },
        {
          name: "ACMUndersized",
          text: "- Image is smaller than the panel size and may be pixelated when sized to fit.",
        },
        {
          name: "ACMSizeMin",
          text: "- Panel size must be 4 inches or larger in width and height.",
        },
        {
          name: "ACMPanelOversizedHeight",
          text: "- Panel Size cannot be taller than the sheet size allows.",
        },
        {
          name: "ACMPanelOversizedWidth",
          text: "- Panel Size cannot be wider than the sheet size allows.",
        },
        {
          name: "AddCartError",
          text: "- There was an error adding everything to the cart. Please wait a few minutes and then try again.",
        },
        {
          name: "CadCutErrorAdhesive",
          text: "- Your cut-file must be embedded on its own layer in the file. The spot color must be named CutContourCad.",
        },
        {
          name: "CadCutErrorRigid",
          text: "- Your cut-file must be embedded on its own layer in the file. The spot color must be named Route.",
        },
        {
          name: "CadCutErrorCorex",
          text: "- Your cut-file must be embedded on its own layer in the file. The spot color must be named Thru-cut.",
        },
        {
          name: "EmbeddedFonts",
          text: "- Embedded Fonts are not allowed. You must convert all fonts to outlines first."
        },
        {
          name: "HorizontalFlute",
          text: "- When selecting Horizontal flute direction, you cannot use wire frames, or steps takes."
        },
        {
          name: "LowDPI",
          text: "- Scaled image is at less than the minimum recommended 100 DPI.",
        },
        {
          name: "NullWidthHeight",
          text: "- Unknown width and height of file.<br>Upload a new file, select a different one, or manually set the dimensions.",
        },
        {
          name: "HasWhitespace",
          text: "- Your image has extra whitespace in it. Please consider uploading a file with any excess whitespace removed.",
        },
        {
          name: "HoleSizeError",
          text: "- Hole to Hole distance can not be greater than panel size can accomodate.",
        },
        {
          name: "Overlap",
          text: "- The maximum printed size for this material is {placeholder} inches. The item will be split with a .25 inch overlay at production's discretion unless another overlap option is selected.",
        },
        {
          name: "TooWideWindow",
          text: "- The maximum width for Window Film is 53 inches.",
        },
        {
          name: "Mismatched",
          text: "- Contour Cut File dimensions do not match uploaded file dimensions.",
        },
        {
          name: "MissingSize",
          text: "- No panel size selected. Please select a Panel Size.",
        },
        {
          name: "MissingCutfile",
          text: "- You have selected a Separate Cutfile but you have not added a cutfile to your image.<br> Please upload a cutfile.",
        },
        {
          name: "cut_finish",
          text: "- You have not selected a cut finish option. Please select an option.",
        },
        {
          name: "MissingLibraryItem",
          text: "- Something went wrong retrieving the image. Please reload your page and try again.",
        },
        {
          name: "product_type",
          text: "- No product selected. Please select a product type.",
        },
        {
          name: "ItemNo",
          text: "- No material selected. Please select a material.",
        },
        {
          name: "LaminateType",
          text: "- Please select finish methods for all items before adding to cart.",
        },
        {
          name: "WouldOverwrite",
          text: "- Upload would overwrite cutfile already in the system.<br>Please rename the file.",
        },
        {
          name: "size",
          text: "- Please choose a panel size."
        },
        {
          name: "sides",
          text: "- Please select <em>Single or Double</em> sided printing.",
        },
        {
          name: "round_corners",
          text: "- Please select a <em>Round Corners</em> value.",
        },
        {
          name: "holes",
          text: "- Please select Yes or No for Drilling Holes.",
        },
        {
          name: "hole_placement",
          text: "- Please select a <em>Drill Holes Placement</em> value.",
        },
        {
          name: "hole_edge_distance",
          text: "- Please select a <em>Drill Holes Edge Distance</em> value.",
        },
        {
          name: "hole_hole_distance",
          text: "- Please select a <em>Drill Holes Hole Distance</em> value.",
        },
        {
          name: "grommets",
          text: "- Please select Yes or No on Grommets."
        },
        {
          name: "self_cut",
          text: "- You must select a Self Cut value."
        },
        {
          name: "Overprice",
          text: "- For jobs that exceed $4,000, a production time of 1-5 business days may be required.<br>Ground or Freight shipping may apply."
        },
      ],
      helpText: [
        {
          name: "Overlap",
          text: "Overlap is the amount of graphic overlap that will be printed between panels.<br>Pricing is affected by material used.<br>Increasing material overlap from the standard 0.25” will increase the price accordingly.",
        },
        {
          name: "holes",
          text: "Holes will be placed in requested location in groups of 2 based on edge and hole distance.",
        },
        {
          name: "hole_edge_distance",
          text: 'Minimum hole edge distance is 3/8" from edge of image to center of hole.',
        },
        {
          name: "hole_hole_distance",
          text: "Hole to hole distance is from center to center of each hole.",
        },
        {
          name: "cut_finish",
          text: "Please ensure your cut file is embedded in the file and has its own layer. For reference, refer to the Art Specs for this material.",
        },
      ],
      project: {
        imageList: [],
        sqFtTotal: "",
        grandTotal: "",
        projectTotal: 0,
        CustomerNumber: "",
      },
      quantity: 1,
    };
  },
  components: {
    'project-item': ProjectLibrary,
    "manage-files-app": ManageFiles
  },
  provide: function () {
    return {
      addToProject: this.addToProject,
      productOptions: this.productOptions,
      selectProduct: this.selectProduct,
      selectProductType: this.selectProductType,
      hideProductType: this.hideProductType,
    }
  },
  created() {
    // log("VuePortal is created ", this.editID);
    if (this.editID) {
      this.editMyCartItem(this.editID);
    }
  },
  mounted() {
    log("VuePortal is mounted", this.franchise);
    UIkit.use(Icons);
    this.setConfigData();
    this.preStagePortal();
  },
  unmounted() {
    // log("VuePortal is unmounted");
  },
  computed: {
    // this does not work. Too complex for computed? Doesn't update for some reason. Works as a method.
    // checkForCorex() {
    //   console.log("checkForCorex");
    //   let _thisCorexFound = this.corexFound;
    //   let corexFound = false;
    //   if (this.project.imageList.length > 0) {
    //     this.project.imageList.forEach((image) => {
    //       if (image.hasOwnProperty("rigid_type")) {
    //         if (image.rigid_type === "corex") {
    //           corexFound = true;
    //         }
    //       }
    //     })
    //   }
    //   return _thisCorexFound = corexFound;
    // },
    // showPrice() {
    //   return this.showPortalPrice();
    // },
    artSpecPath() {
      let returnValue = "";
      let specSheets = this.getFileSpecs();
      returnValue = specSheets['Adhesive'];
      if (this.product_type === "acm" || this.product_type === "rigid") {
        // console.log("artSpecPath", specSheets);
        returnValue = specSheets['ACM'];
      }
      if (this.activeImage.rigid_type === "corex") {
        returnValue = specSheets['Corex'];
      }
      this.artSpecLink = returnValue;
      return returnValue;
    },
    cartTotalText() {
      let returnValue = "Total Sq Ft: ";
      if (this.product_type === "rigid") {
        returnValue = "Number of Sheets: ";
      }
      return returnValue;
    },
    displayPreviewSheet() {
      return this.UI.acmLayout.currentPreviewSheet + 1;
    },
    disableNextButton() {
      let returnValue = false;
      let maxCount = this.UI.acmLayout.previewSheetCount - 1;
      if (this.UI.acmLayout.currentPreviewSheet == maxCount) {
        returnValue = true;
      }
      return returnValue;
    },
    showFlutes() {
      //console.log("showFlutes");
      let returnValue = false;
      if (this.product_type === "rigid") {
        if (this.activeImage.rigid_type === "corex") {
          returnValue = true;
        }
      }
      return returnValue;
    },
    fluteDirection() {
      let returnValue = "url(#fluteH)";
      if (this.activeImage.Rotate === "yes") {
        returnValue = "url(#fluteV)";
      }
      return returnValue;
    },
    libraryButtonText() {
      let returnValue = "Choose Product Type First.";
      if (this.product_type) {
        returnValue = "Click to Add Image to Project.";
      }
      return returnValue;
    },
    sheetPreview() {
      let returnValue = false;
      if (this.product_type === "rigid") {
        // log("sheetPreview", this.activeImage);
        if (this.activeImage.hasOwnProperty("size") && this.activeImage.size != "Select") {
          returnValue = true;
        }
      }
      return returnValue;
    },
    showSVGOverlay() {
      let returnValue = false;
      if (this.product_type === "rigid") {
        if (this.activeImage.product_type === "rigid" && this.activeImage.round_corners > 0) {
          returnValue = true;
        }
        if (this.activeImage.hole_placement !== "Select") {
          if (this.activeImage.hole_edge_distance !== "Select") {
            if (this.activeImage.hole_hole_distance > 0) {
              returnValue = true;
            }
          }
        }
      }
      return returnValue;
    },
    thisPanelImageCount() {
      // log("running thisPanelImageCount computed property");
      let returnValue = this.UI.acmLayout.panelImageCount;
      let current = this.UI.acmLayout.currentPreviewSheet;
      // reset to 0 if the current has gotten over. Happens when FluteDirection changes
      // log("thispanelimage current", current);
      // log("thispanelimage length", this.UI.svgPreview.panelImages.length);
      if (current >= this.UI.svgPreview.panelImages.length) {
        current = 0;
        this.UI.acmLayout.currentPreviewSheet = 0;
      }
      if (this.UI.svgPreview.panelImages.length > 0) {
        returnValue = this.UI.svgPreview.panelImages[current].length;
      }
      return returnValue;
    },
    displayPanelImages() {
      return this.UI.svgPreview.panelImages;
    },
    productOptions() {
      // Sets the UI.productOptions for Index to display.
      // Had to do this because returning the data in mounted did not work.
      // Delay in getting response caused issues with mounting. This was faster.
      // console.log("computed ProductOptions");
      let products = [{
        "OptionKey": "product_type",
        "ChoiceName": "Loading",
        "ChoiceValue": "loading",
        "ChoiceAvailable": "true"
      }];
      // log("productOptions dtOptionChoices", this.configData.dtOptionChoices);
      // This is all options chocices
      if (this.configData.dtOptionChoices) {
        // reset products to blank
        products = [];
        if (this.configData.dtOptionChoices.length > 1) {
          // we have more than one choice, get config data
          const configData = this.configData.dtOptions;
          // find the Products section
          let option = _.filter(configData, ['OptionName', 'Products']);
          // extract the key and use it to filter out our choices.
          let key = option[0].OptionKey;
          let choices = _.filter(this.configData.dtOptionChoices, ['OptionKey', key]);
          // console.log("choices", choices);
          // limit our choices based on an array of products we want to show on this page.
          if (choices.length > 0) {
            _.forEach(this.UI.allowedProductTypes, function (r, i) {
              let p = _.find(choices, ['ChoiceValue', r]);
              // console.log("p", p);
              if (p) {
                if (p.ChoiceAvailable === true) {
                  // products.push(_.find(choices, ['ChoiceValue', r]));
                  products.push(p);
                }
              }
            });
          }
        }
      }
      // console.log("products", products);
      return products;
    },
    productOptionChoices() {
      // console.log("productOptionChoices");
      let choiceData = {};
      choiceData = _.clone(this.configData.dtOptions);
      // Need to remove product_type
      _.pullAllBy(choiceData, [{
        'OptionKey': 'product_type'
      }], 'OptionKey');
      return choiceData;
    },
    productOptionDescriptions() {
      return this.configData.dtOptionChoices;
    },
    sheetNIndex() {
      let returnValue = 1;
      const maxIndex = this.UI.acmLayout.previewSheetCount - 1;
      if (this.UI.acmLayout.currentPreviewSheet <= maxIndex) {
        returnValue = this.UI.acmLayout.currentPreviewSheet + 1;
      }
      if (this.UI.acmLayout.currentPreviewSheet == maxIndex) {
        // console.log("I am groot");
        returnValue = maxIndex;
      }
      // console.log("sheetNIndex: " + returnValue);
      return returnValue;
    },
    sheetPIndex() {
      let returnValue = 0;
      if (this.UI.acmLayout.currentPreviewSheet > 0) {
        returnValue = this.UI.acmLayout.currentPreviewSheet - 1;
      }
      return returnValue;
    },
    showBulletin() {
      let returnValue = false;
      if (this.activeImage.hasOwnProperty("ItemNo")) {
        if (this.activeImage.ItemNo != "Select") {
          returnValue = true;
        }
      }
      if (this.product_type === "rigid") { //don't have a bulletin for ACM yet
        returnValue = false;
      }
      return returnValue;
    },
    showHeightWidth() {
      let returnValue = false;
      if (this.product_type !== 'rigid') {
        returnValue = true;
      }
      if (this.product_type === 'rigid') {
        if (this.activeImage.size === 'custom') {
          returnValue = true;
        }
      }
      return returnValue;
    },
    showSheetPager() {
      // log("showSheetPager");
      let returnValue = false;
      if (this.product_type === "rigid") {
        // log("groot", this.project.sqFtTotal);
        if (this.project.sqFtTotal > 1) {
          returnValue = true;
        }
      }
      returnValue = true;
      return returnValue;
    },
    totalItems() {
      return this.getTotalItems();
    },
    viewBoxCalc() {
      // log("viewBoxCalc", this.UI.svgPreview);
      let width = this.UI.svgPreview.width;
      let height = this.UI.svgPreview.height;
      return "0 0 " + width + " " + height;
    },
    viewSheetCalc() {
      // log("viewSheetCalc");
      // NOTICE material sheet size is w x h
      let sheetSize = this.activeImage.MaterialSheetSize.split(",");
      let width = sheetSize[0];
      let height = sheetSize[1];
      if (this.product_type === "rigid") {
        // log("viewSheetCalc", sheetSize);
        if (this.activeImage.hasOwnProperty("Rotate")) {

          if (this.activeImage.Rotate === "yes") {
            // log("horizontal view box");
            width = sheetSize[1];
            height = sheetSize[0];
          }
        }
      }
      return "0 0 " + width + " " + height;
    },
  },
  methods: {
    checkForCorex() {
      // console.log("checkForCorex");
      let corexFound = false;
      // check if corex is applied to any image. Is this necessary since material option locked to each image? i.e can't be different?
      if (this.project.imageList.length > 0) {
        this.project.imageList.forEach((image) => {
          if (image.hasOwnProperty("rigid_type")) {
            if (image.rigid_type === "corex") {
              corexFound = true;
            }
          }
        })
        // this.corexFound = corexFound;
      } else if (this.activeImage.hasOwnProperty("rigid_type")) {
        if (this.activeImage.rigid_type === "corex") {
          corexFound = true;
          // this.corexFound = true;
        }
      }

      if (corexFound) {
        this.corexFound = true;
      } else {
        this.corexFound = false;
      }
      // console.log("corexFound: ", corexFound);
      // console.log("this.corexFound: ", this.corexFound);
      return corexFound;
    },
    preStagePortal() {
      if (this.franchise === 'EPD') {
        console.log("Prestage Product Type");
        this.product_type = 'adhesive';
        this.activeImage.product_type = "adhesive";
        this.UI.libraryActive = true;
        this.UI.showRestartPortal = true;
      }
      if (this.wizardStore.wizardActive) {
        console.log("staging from wizard");
        this.stageFromTileSelection();
      }
    },
    stageFromTileSelection() {
      console.log("wizardStore", this.wizardStore.currentProduct_type);
      if (this.wizardStore.currentProduct_type == 'rigid') {
        // log("Staging from tile menu - rigid");
        this.product_type = "rigid";
        this.activeImage.product_type = "rigid";
        this.activeImage.rigid_type = this.wizardStore.substrateValue;
        this.activeImage.ItemNo = this.wizardStore.productType;
        this.activeImage.rigid_type = this.wizardStore.substrateOptionValue;
        // log("wizard Option Value: ", this.wizardStore.substrateOptionValue);
        this.activeImage.RigidText = this.wizardStore.substrateName;
      } else {
        log("Staging from tile menu - adhesive");
        this.product_type = this.wizardStore.currentProduct_type;
        this.activeImage.product_type = this.wizardStore.currentProduct_type;
        this.activeImage.ItemNo = this.wizardStore.currentSubstrateValue;
        this.activeImage.tile = this.wizardStore.currentSubstrateTile;
      }
      this.UI.showRestartPortal = true;
      this.wizardStore.setwizardActive(false);
      this.wizardStore.setSubstrateName("");
      this.wizardStore.setSubstrateValue("");
      this.wizardStore.setProductType("");
      this.UI.libraryActive = true;
      // console.log("Staging ", this.activeImage);
    },
    debugImageList() {
      // console.log("Each item in the imageList: ");
      this.project.imageList.forEach(function (imageItem) {
        // console.log(imageItem);
      });
    },
    debugErrorList() {
      // console.log("Each item in the active image errorList: ");
      _.forEach(this.activeImage.errorList, function (errorItem) {
        // console.log(errorItem);
      });
      // console.log("Each item in the active image warningList: ");
      _.forEach(this.activeImage.warningList, function (warningItem) {
        // console.log(warningItem);
      });
    },
    async addToProject(selectedItem) {
      // log("VuePortal addToProject", selectedItem);
      // needs to call for more image information.
      const url = this.baseUrl + "/Library" + "/GetLibraryItemDetails";
      let call = selectedItem;
      const { user } = useAuthStore();
      const userId = user.Id;
      call.userId = userId;
      // console.log("addToProject item", call);
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const imageResponse = await response.json();
      // console.log("addToProject result: ", imageResponse);
      if (imageResponse.Status === "ok") {
        this.addedImage = imageResponse.Data;
      }
      if (imageResponse.Status === "exception") {
        console.log(imageResponse.Message);
      }
    },
    addToCart() {
      // log("addToCart");
      // we will need this
      let _this = this;
      // update recent changes to active image.
      _this.syncImageList();

      // clear previous error indicators.
      _this.cart.stopCartSubmit = false;
      _this.cart.showCartAnyway = false;
      this.cart.errors = "";

      // Manage the cartText
      let data = _this.project.imageList;
      if (data.length == 0) {
        // not sure we need this anymore. s/b impossible to get here.
        this.cart.cartText = " - No items to add";
      }
      if (data.length > 1) {
        this.cart.cartText = "Item(s) added to cart."
      }
      if (this.cart.updateCart === true) {
        this.cart.cartText = "Item updated in cart."
      }

      // Use dtOptions to check for available options and add error if not set
      let projectData = this.project.imageList;
      let options = _.clone(this.configData.dtOptions);
      // sorry could not find a lodash for this headbanging oneliner.
      // This is to loop through the options and check conditions instead of hardcoding each one.
      // ... is Spread (copy object)
      // => is filtering by the object key given.
      //.values() gets is back and puts it in the []
      let uniqOptions = [...new Map(options.map((item) => [item["OptionKey"], item])).values()];
      // Get Option Conditions for managing errors
      _.forEach(uniqOptions, function (cond, index) {
        // console.log("Condition: " + cond.OptionKey);
        // PULL all errors to clear error list. this.cart.errors should already be [];
        if (_this.checkOptionAvailable(cond.OptionKey)) {
          _.forEach(projectData, function (image, index) {
            _this.pullError(cond.OptionKey, image);
            // console.log("Prop:" + image[cond.OptionKey]);
            if (image[cond.OptionKey] === "Select" || image[cond.OptionKey] == "") {
              // console.log("I caught a FISH!");
              if (_this.checkOptionAvailable(cond.OptionKey, image)) {
                // console.log("The FISH is a keeper!");
                _this.pushError(cond.OptionKey, image);
              }
            }
          });
        }
      });
      // Use existing error list instead of building it all again.
      // Reworked to handle pushing and pulling errors from each image instead as a global.
      _.forEach(projectData, function (item, index) {
        // log("errorList item", item);
        if (item.errorList.length > 0) {
          // log("we have error list in cart", item);
          _this.cart.stopCartSubmit = true;
          _.forEach(item.errorList, function (value, key) {
            _this.pushCartError(value);
          });
        }

        // push all warnings to cartErrors when all errors are cleared.
        if (_this.cart.stopCartSubmit === false) {
          if (item.warningList.length > 0) {
            let warnings = _.uniq(item.warningList);
            _this.cart.showCartAnyway = true;
            _this.cart.stopCartSubmit = true;
            item.OrderNotes = "";
            _.forEach(warnings, function (value, key) {
              _this.pushCartError(value);
              // put cart errors in orderNotes so we can push them to final order in Bullseye
              // item.OrderNotes = item.OrderNotes + " " + value.replace(/(<([^>]+)>)/gi, "");
              item.OrderNotes = item.OrderNotes + value.replace(/(<([^>]+)>)/gi, "") + "; ";
            });
          }
        }
      });

      //Check Project Dollar Amount.
      if (parseFloat(_this.project.projectTotal) > 4000) {
        var item = _.find(this.errorTypes, ["name", "Overprice"]);
        if (item) {
          _this.cart.showCartAnyway = true;
          _this.cart.stopCartSubmit = true;
          _this.pushCartError(item.text);
        }
      }

      //console.log("cart errors: " + this.cart.errors.length);
      //console.log(this.cart.errors);
      // Common error handling. Set Error Messages
      if (_this.cart.stopCartSubmit === true) {
        // log('show AddCartStop', _this.cart.showCartAnyway);
        if (_this.cart.showCartAnyway === true) {
          // log("show AddCartWarning");
          UIkit.modal("#AddCartWarning").show();
        } else {
          UIkit.modal("#AddCartStop").show();
        }
      }

      if (_this.cart.stopCartSubmit === false) {
        // log("no errors calling addToCart.");
        // this.debugImageList();
        const url = this.baseUrl + "/Store/AddToCart";
        fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: this.dataPush(data)
        }).then(responseData => {
          // log("AddToCart responseData: ", responseData);
          return responseData.json();
        }).then((jsondata) => {
          // log("AddToCart jsonData: ", jsondata);
          let response = jsondata;
          if (response.Status === "ok") {
            _this.project.imageList = [];
            _this.cleanLayout();
            _this.cart.continueCreating = true;
            UIkit.modal("#AddCartSuccess").show();
          } else {
            _this.cart.cartText = "There was a server error adding items to your cart."
            UIkit.modal("#AddCartFail").show();
          }
        });
      }
    },
    goToCart() {
      // log("Go To Cart...");
      router.push({ name: 'cart' })
    },
    addToCartAnyway() {
      // console.log("addToCartAnyway");
      // console.log("material type: ", this.project.imageList[0].rigid_type);
      // going to need _this;
      let _this = this;
      const url = this.baseUrl + "/Store/AddToCart";
      let data = _this.project.imageList;
      // this.debugImageList();
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: this.dataPush(data)
      }).then(responseData => {
        return responseData.json();
      }).then((jsondata) => {
        // log("AddToCartAnyway jsonData: ", jsondata);
        let response = jsondata;
        if (response.Status === "ok") {
          _this.project.imageList = [];
          _this.cleanLayout();
          _this.cart.continueCreating = true;
          UIkit.modal("#AddCartSuccess").show();
        } else {
          _this.cart.cartText = "There was a server error adding items to your cart."
          UIkit.modal("#AddCartFail").show();
        }
      });
    },
    addImageToProject(newImage) {
      console.log("addImagetoProject");
      // log("AddImageToProject newImage", newImage);
      // log("newImage product_type", this.product_type);
      // log("newImage FullUrl: ", newImage.FullUrl);
      // look for pricing data on activeImage, save to pricing data if exists
      const { user } = useAuthStore();
      const userId = user.Id;
      // If we already have pricing data, we will need to apply it.
      if (this.activeImage.hasOwnProperty("ItemNo")) {
        // only if we have no images in the project
        if (this.project.imageList.length === 0) {
          if (this.activeImage.ItemNo != "Select") {
            // log("setting checkPricingData");
            this.checkPricingData = Object.assign({}, this.activeImage);
          }
        }
      }

      // let serverPath = this.getFilePath(newImage.FullUrl, newImage.Name, userId);
      // log("serverPath", serverPath);
      //Copy just the properties, not the Kendo events to our project.activeImage
      // Removes the _events
      let jsonString = JSON.stringify(newImage);
      // Makes it an object.
      this.activeImage = JSON.parse(jsonString);
      // log("afterParse", this.activeImage);
      // We have an image. Not just calc pricing.
      this.UI.showNoImagePrice = false;
      // Have to manually set additional properties since they are not passed
      this.activeImage.product_type = this.product_type;
      // this.activeImage.page_type = this.product_type;
      this.activeImage.userId = userId;
      this.activeImage.Quantity = 1;
      // this.activeImage.ServerPath = serverPath;
      this.activeImage.errorList = [];
      this.activeImage.warningList = [];
      this.activeImage.ItemNo = "Select";
      this.activeImage.Price = "";
      this.activeImage.CartID = "";
      this.activeImage.SetId = "";

      // Object lit to configure specific category
      this.setImageDefaults(this.product_type);

      // If we had pricing data, reset properties to match
      if (!_.isEmpty(this.checkPricingData)) {
        let _this = this;
        _.forOwn(this.checkPricingData, function (v, k) {
          _this.activeImage[k] = v;
        });
      }

      // log(_this.activeImage);
      //Putting customer number on the project
      // log("do I have a customer number or not?", newImage);
      // log("do I have a customer number or not?", this.project);
      // LineNumber as an index. Borrowed from old code, but it is 0 based now.
      let listLength = this.project.imageList.length;
      this.activeImage.LineNumber = listLength;

      // push the image onto our image list.
      // log("pushing this to image list: ", this.activeImage);
      this.project.imageList.push(this.activeImage);

      if (this.project.imageList.count < 1) {
        _this.UI.showNoImagePrice = true;
      }

      this.UI.selectedImage = this.activeImage.Id;
      // log("set selectedImage", this.UI.selectedImage);
      // forces images to show when they are not adhesive
      // this.resizeLayout();
      // Following flow of old code.
      this.setConfigData();
      this.updatePricing();
      this.pdfToolBoxCheck(this.activeImage.FullUrl);
    },
    setImageDefaults(product) {
      // My first Object Literal
      let _this = this;
      let defaults = {
        'adhesive': function () {
          _this.activeImage.StretchCenter = false;
          _this.activeImage.ColorMatching = "";
          _this.activeImage.LaminateType = "Select";
          _this.activeImage.Overlap = "Select";
          _this.activeImage.cut_finish = "Select";
          // log("configured adhesive", _this.activeImage);
        },
        'Wallcoverings': function () {
          _this.activeImage.StretchCenter = false;
          _this.activeImage.ColorMatching = "";
          _this.activeImage.LaminateType = "Select";
          _this.activeImage.Overlap = "Select";
          _this.activeImage.cut_finish = "Select";
          // log("configured Wallcoverings", _this.activeImage);
        },
        'Window Decoration': function () {
          _this.activeImage.StretchCenter = false;
          _this.activeImage.ColorMatching = "";
          _this.activeImage.LaminateType = "Select";
          _this.activeImage.Overlap = "Select";
          _this.activeImage.cut_finish = "Select";
          // log("configured Window Decoration", _this.activeImage);
        },
        'Magnetic': function () {
          _this.activeImage.StretchCenter = false;
          _this.activeImage.ColorMatching = "";
          _this.activeImage.LaminateType = "Select";
          _this.activeImage.Overlap = "Select";
          _this.activeImage.cut_finish = "Select";
          // log("configured Magnetic", _this.activeImage);
        },
        'rigid': function () {
          _this.activeImage.rigid_type = "Select";
          _this.activeImage.size = "Select";
          _this.activeImage.sides = "Select";
          _this.activeImage.round_corners = "Select";
          _this.activeImage.holes = "Select";
          _this.activeImage.hole_placement = "Select";
          _this.activeImage.hole_edge_distance = "Select";
          _this.activeImage.grommets = "Select";
          _this.activeImage.self_cut = "Select";
          _this.activeImage.StretchCenter = false;
          _this.activeImage.finishtype = "";
          _this.activeImage.cut_finish = "Select";
          _this.activeImage.MaterialSheetSize = "Select";
          _this.activeImage.SheetWidth = "";
          _this.activeImage.SheetHeight = "";

          // corex properties
          _this.activeImage.LaminateType = "Select";
          _this.activeImage.Rotate = "no";

          // lock these properties to already selected values
          if (_this.project.imageList.length > 0) {
            let firstImage = _this.project.imageList[0];
            // console.log(firstImage);
            _this.activeImage.rigid_type = firstImage.rigid_type;
            _this.activeImage.ItemNo = firstImage.ItemNo;
            _this.activeImage.size = firstImage.size;
            _this.activeImage.sides = firstImage.sides;
            // _this.activeImage.InchWidth = firstImage.InchWidth;
            // _this.activeImage.InchHeight = firstImage.InchHeight;
            _this.activeImage.MaterialSheetSize = firstImage.MaterialSheetSize;
            _this.activeImage.SheetWidth = firstImage.SheetWidth;
            _this.activeImage.SheetHeight = firstImage.SheetHeight;
            _this.activeImage.LaminateType = firstImage.LaminateType;
            //console.log(_this.activeImage);
          }
          // log("configured rigid", _this.activeImage);
        }
      }
      return defaults[product]();
    },
    applyOptionsToAll() {
      // console.log("applyOptionsToAll");
      // Going to need _this
      let _this = this;
      let ui = this.UI.applyAllOptions[this.product_type.toString()];
      // The options applyAll applies to, minus the Button.;
      if (ui) {
        let applyAllOptions = Array.from(ui);
        let optionsList = _.pull(applyAllOptions, "Button");
        // console.log(optionsList);
        _.forEach(_this.project.imageList, function (image, key) {
          //console.log(image);
          _.each(optionsList, function (option) {
            //console.log("current option: " + option);
            image[option] = _this.activeImage[option];
          });
          // HACK to get Material and Finish full text into the image.
          // Populates for ImageCardTemplate display and StoreController logic.
          if (image.hasOwnProperty('LaminateType')) {
            // console.log(image.LaminateType);
            // console.log(_this.configData.dtOptionChoices);
            let finish = _.find(_this.configData.dtOptionChoices, ['ChoiceValue', image.LaminateType]);
            // console.log(finish);
            if (finish) {
              image.FinishText = finish.ChoiceName;
            }
          }
          if (image.hasOwnProperty('ItemNo')) {
            let material = _.find(_this.configData.dtOptionChoices, ['ChoiceValue', image.ItemNo]);
            if (material) {
              image.MaterialText = material.ChoiceName;
              if (_this.product_type == "rigid") {
                let rigidType = _.find(optionChoices, ['ChoiceValue', this.activeImage.rigid_type]);
                this.activeImage.MaterialText = rigidType.ChoiceName + " " + material.ChoiceName;
              }
            }
          }

        });
        this.updatePricing();
      }
    },
    checkForImageErrors() {
      // log("checkForImageErrors", this.activeImage);
      //this.debugImageList();
      // poor dpi

      // Clear Material and Finish Errors
      if (this.activeImage.ItemNo !== "Select") {
        //console.log("clearing material errors");
        this.pullError('ItemNo');
      }
      if (this.activeImage.LaminateType !== "Select") {
        this.pullError('LaminateType');
      }
      this.checkScaledDPI();
      // Lots of whitespace
      this.checkWhitespace();
      // Double Check Cut file is uploaded.
      this.checkCutfile();
      // Check for outline fonts.
      this.checkOutlineFonts();
      // Width and Height Empty
      this.pullError('NullWidthHeight');
      if (this.activeImage.InchWidth === null || this.activeImage.InchWidth <= 0) {
        this.pushError('NullWidthHeight');
      }
      if (this.activeImage.InchHeight === null || this.activeImage.InchHeight <= 0) {
        this.pushError('NullWidthHeight');
      }
      this.checkPanelSize();
      this.checkHolePlacement();
      this.checkFluteDirection();

    },
    checkFluteDirection() {
      if (this.product_type === "rigid") {
        this.pullWarning("HorizontalFlute");
        if (this.activeImage.hasOwnProperty("rigid_type")) {
          if (this.activeImage.rigid_type === "corex") {
            if (this.activeImage.Rotate === "no") {
              this.pushWarning("HorizontalFlute");
            }
          }
        }
      }
    },
    checkForLargeImage() {
      // Checks if an image will be downsized to fit the chosen panel size.
      // console.log("checkForLargeImage");
      let returnValue = false;
      let imageHeight = parseFloat(this.activeImage.InchHeight);
      let imageWidth = parseFloat(this.activeImage.InchWidth);
      let panelHeight = this.UI.acmLayout.panelHeight;
      let panelWidth = this.UI.acmLayout.panelWidth;
      let oversizedH = imageHeight > panelHeight;
      let oversizedW = imageWidth > panelWidth;
      if (oversizedH && oversizedW) {
        returnValue = true;
      }
      return returnValue;
    },
    checkForSmallImage() {
      //Checks if an image will be upsized to fit the chosen panel size
      // console.log("checkForSmallImage");
      let returnValue = false;
      let imageHeight = parseFloat(this.activeImage.InchHeight);
      let imageWidth = parseFloat(this.activeImage.InchWidth);
      let panelHeight = this.UI.acmLayout.panelHeight;
      let panelWidth = this.UI.acmLayout.panelWidth;
      let undersizedH = imageHeight < panelHeight;
      let undersizedW = imageWidth < panelWidth;
      if (undersizedH && undersizedW) {
        returnValue = true;
      }
      return returnValue;
    },
    checkHolePlacement(c2c) {
      if (this.activeImage.hasOwnProperty("hole_hole_distance")) {
        // log("checkHolePlacement");
        let newVal = 0;
        if (!c2c) {
          newVal = this.activeImage.hole_hole_distance;
        } else {
          newVal = c2c;
        }
        this.UI.svgPreview.cToCenter = 2;
        // log("checkHolePlacement", newVal);
        this.UI.svgPreview.holeSizeError = false;
        this.pullError("HoleSizeError");
        if (this.product_type === "rigid") {
          //height and width must account for hole size
          let height = parseInt(this.UI.acmLayout.panelHeight) - 1;
          let width = parseInt(this.UI.acmLayout.panelWidth) - 1;
          this.UI.svgPreview.cToCenter = newVal;
          if (this.activeImage.hole_placement.search("left") >= 0 || this.activeImage.hole_placement.search("right") >= 0) {
            // if (newVal >= (height / 2)) {
            if (newVal >= height) {
              this.UI.svgPreview.holeSizeError = true
              this.pushError("HoleSizeError");
            }
          }
          if (this.activeImage.hole_placement.search("top") >= 0 || this.activeImage.hole_placement.search("bottom") >= 0) {
            // if (newVal >= (width / 2)) {
            if (newVal >= width) {
              this.UI.svgPreview.holeSizeError = true
              this.pushError("HoleSizeError");
            }
          }
        }
      }
    },
    checkOptionAvailable(OptionKey, image) {
      //Using the dtOptionCondition determine if the option is valid for the users choices.
      //console.log("checkOptionAvailable: " + OptionKey);
      //console.log("Current configuration data: ");
      //console.log(this.configData);
      const OptionConditions = this.configData.dtOptionCondition;
      const conditions = _.filter(OptionConditions, ['OptionKey', OptionKey]);
      //console.log("checkOptionAvailable: conditions:");
      //console.log(conditions);
      if (conditions.length > 0) {
        //console.log("activeImage:");
        //console.log(this.activeImage);
        for (let k in conditions) {
          let v = conditions[k];
          //console.log("other option key: " + v.OtherOptionKey);
          let currentState = this.activeImage[v.OtherOptionKey];
          if (image) {
            currentState = image[v.OtherOptionKey];
          }
          //console.log("currentState: " + currentState);
          //console.log("Condition: " + v.Condition)
          //console.log("ConditionValue: " + v.ConditionValue);

          // ** NOTE the reverse test for the implied case. **
          switch (v.Condition) {
            case 'EQ':
              if (!(currentState == v.ConditionValue)) {
                return false;
              }
              break;
            case 'NEQ':
              if (currentState == v.ConditionValue) {
                return false;
              }
              break;
            case 'GT':
              if (!(parseFloat(currentState) > parseFloat(v.ConditionValue))) {
                return false;
              }
              break;
            default:
          }
        }
      }
      //console.log("Default true");
      return true;
    },
    checkPanelSize() {
      // log("checking panel size");
      //Runs three size checks to determine if image is smaller or larger than panel
      //and that it is large enough if using a custom size.
      if (this.product_type === "rigid") {

        //Common regardless of how you size it.
        this.pullWarning("ACMOversized");
        this.pullWarning("ACMUndersized");
        this.pullError("ACMSizeMin");
        this.pullError("ACMPanelOversizedWidth");
        this.pullError("ACMPanelOversizedHeight");
        //console.log(sizeArray);
        let oversized = this.checkForLargeImage();
        let undersized = this.checkForSmallImage();
        // image is too large for chosen size
        if (oversized) {
          //console.log("starlord");
          this.pushWarning('ACMOversized');
        }
        // image is too small for chosen size
        if (undersized) {
          //console.log("groot");
          this.pushWarning('ACMUndersized');
        }
        // Have to have a pael size of at least 4 inches
        if (this.activeImage.size === "custom") {
          //console.log("checking custom panel size");
          // must be greater than 4" in size
          if (parseFloat(this.activeImage.InchHeight) < parseFloat(this.UI.acmLayout.minsize) || parseFloat(this.activeImage.InchWidth) < parseFloat(this.UI.acmLayout.minsize)) {
            //console.log("sizeMinError");
            this.pushError("ACMSizeMin");
          }
          // cannot be wider than max allowed in panel options.

          if (this.activeImage.hasOwnProperty('InchWidth')) {
            if (parseFloat(this.activeImage.InchWidth) > parseFloat(this.UI.acmLayout.panelMaxWidth)) {
              this.pushError("ACMPanelOversizedWidth");
            }
          }
          if (this.activeImage.hasOwnProperty('InchHeight')) {
            if (parseFloat(this.activeImage.InchHeight) > parseFloat(this.UI.acmLayout.panelMaxHeight)) {
              this.pushError("ACMPanelOversizedHeight");
            }
          }
        }
      }
    },
    checkScaledDPI() {
      // console.log("checkScaledDPI");
      this.pullWarning("LowDPI");
      let scale = -1;
      let scaledDPI = 0;

      if (this.activeImage.InchWidth > 0) {
        scale = 1;
      }
      if (this.activeImage.DPI > 0) {
        scaledDPI = this.activeImage.DPI / scale;
      }

      if (scaledDPI < 100 && scaledDPI > 0) {
        this.pushWarning('LowDPI');
      }
    },
    checkWhitespace() {
      // console.log("checkWhitespace");
      this.pullWarning("HasWhitespace");

      if (this.activeImage.HasWhitespace) {
        this.pushWarning('HasWhitespace');
      }
    },
    checkCutfile() {
      //Cut file is now allowed for rigid products
      // Separate is for backward compatibility
      // log("checkCutfile", this.activeImage.toolbox.route);
      // this.debugImageList();
      this.pullError("CadCutErrorAdhesive");
      this.pullError("CadCutErrorRigid");
      this.pullError("CadCutErrorCorex");
      if (this.activeImage.hasOwnProperty('toolbox')) {
        // console.log("has toolbox");
        if (this.activeImage.cut_finish === 'embedded') {
          // console.log("before starting checkCAd");

          if (this.product_type !== "rigid") {
            // log("checkCutfile activeImage toolbox", this.activeImage.toolbox);
            // log("isFalse", this.activeImage.toolbox.cut_contour);
            if (this.activeImage.toolbox.cut_contour == false) {
              // log("bad Cutcontour spot");
              this.pushError("CadCutErrorAdhesive");
            }
          }

          if (this.product_type === "rigid") {
            // unique rules for rigid_types
            // corex
            if (this.activeImage.rigid_type === "corex") {
              if (this.activeImage.toolbox.thru == false) {
                // log("bad route spot");
                this.pushError("CadCutErrorCorex");
              }
              // acm all others
            } else {
              if (this.activeImage.toolbox.route == false) {
                // log("bad route spot");
                this.pushError("CadCutErrorRigid");
              }
            }
          }
        }
      }

    },
    pdfToolBoxCheck(imageFile) {
      // Makes call to PDFToolbox to determine image properties.
      // console.log("pdfToolBoxCheck start", imageFile);
      const url = this.baseUrl + "/Library/CheckCAD";
      const { user } = useAuthStore();
      this.activeImage.toolbox = {};
      if (imageFile) {
        let call = {
          FullUrl: imageFile,
          userId: user.Id
        };
        // console.log("pdfToolBoxCheck call", call);
        fetch(url, {
          method: 'POST',
          headers: authHeader(url),
          body: JSON.stringify(call)
        }).then(response => {
          return response.json();
        }).then((jsondata) => {
          this.activeImage.toolbox = jsondata;
          this.checkForImageErrors();
          // log("pdfToolBoxCheck finished", this.activeImage.toolbox);
        });
      }
    },
    checkOutlineFonts() {
      //Embedded Fonts are not allowed. They need to be outlined
      // log("checkOutlineFonts", this.activeImage.toolbox.embedded_fonts);
      this.pullError("EmbeddedFonts");
      // get defensive about having an image
      if (this.activeImage.hasOwnProperty("ServerPath")) {
        if (this.activeImage.toolbox.embedded_fonts === true) {
          this.pushError("EmbeddedFonts");
        }
      }
    },
    choiceChange() {
      // console.log("choiceChange current activeImage: ");
      // console.log(this.activeImage);
      this.setConfigData();
    },
    clearErrors() {
      // Removes all errors from the users error list
      // console.log("clearing all errors");
      this.activeImage.errorList = [];
      // this.showErrorList();
    },
    clearWarnings() {
      // Removes all errors from the users error list
      this.activeImage.warningList = [];
      // this.showErrorList();
    },
    cleanLayout() {
      // console.log("cleanLayout");
      this.UI.layoutPreview.backgroundImage = "";
      this.UI.layoutPreview.showProductPreview = false;
      // this.UI.layoutPreview.showInstructionPane = true;
      this.UI.svgPreview.panelImages = [];
    },
    confirmDeleteFromProject() {
      // console.log("confirmDeleteFromProject");
      const index = this.activeImage.LineNumber;
      //console.log("the delete index is: " + index);
      // data
      this.project.imageList.splice(index, 1);
      // visual
      UIkit.modal("#DeleteConfirmationModal").hide();
      //console.log("image list length: " + this.project.imageList.length)
      if (this.project.imageList.length > 0) {
        this.reorderList();
        //console.log("selecting 0");
        // make the activeImage the first image.
        this.selectActiveImage(0);
      } else {
        //console.log("No Images Remain");
        // resize for a blank portal.
        this.activeImage = {};
        this.cleanLayout();
        this.errorList = [];
        // this.showErrorList();
      }
      // clear project totals when we have no products.
      if (this.project.imageList.length > 0) {
        this.setPanelImages();
        this.updatePricing();
      } else {
        this.project.grandTotal = 0;
        this.project.sqFtTotal = 0;
        this.project.projectTotal = 0;
      }
      // console.log("after remove image from project");
      //this.debugImageList();
    },
    deleteFromProject(index) {
      // console.log("deleteFromProject");
      // this.debugImageList();
      this.activeImage = this.project.imageList[index];
      this.activeImage.LineNumber = index;
      this.UI.deleteImageName = this.activeImage.Name;
      UIkit.modal("#DeleteConfirmationModal").show();
    },
    async getCartItem(cartId) {
      // log("getCartItem",cartId);
      const url = this.baseUrl + "/Portal/GetCartItem";
      const { user } = useAuthStore();
      const call = {
        "userId": user.Id,
        "cartId": cartId,
      };
      // log("getCartItem", call);
      let cartItem = {};
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let cartResponse = await response.json();
      // log("getCartItem result: ", cartResponse);
      if (cartResponse.Status === "ok") {
        cartItem = cartResponse.Data;
      }
      return cartItem;
    },
    async editMyCartItem(cartId) {
      // log("VuePortal editMyCartItem", cartId);
      const authStore = useAuthStore();
      const user = authStore.user;
      const userId = user.Id;
      // log("UI is start", this.UI.svgPreview);
      this.UI.showNoImagePrice = false;
      let item = await this.getCartItem(cartId);
      // log("cartItem", item);
      // set the active image and get us into the correct UI for the product type.
      this.activeImage = item;
      // console.log("activeImage");
      this.activeImage.userId = userId;
      // let serverPath = this.getFilePath(item.FullUrl, item.Filename, userId);
      this.activeImage.ServerPath = item.FullFilePath;
      this.product_type = item.product_type;
      this.cart.updateCart = true;
      // additional properties coming from cart.
      this.activeImage.SetId = item.SetId;
      this.activeImage.SetName = item.SetName;
      // console.log(this.activeImage);
      // Get current list length
      let listLength = this.project.imageList.length;
      // LineNumber should match?
      this.activeImage.LineNumber = listLength;
      // push the image onto our image list.
      this.project.imageList.push(this.activeImage);
      // deriddle cad. It sets everything to false or none at the start, so ok for all product types.
      //this.manageCADCut();
      this.UI.libraryActive = true;
      this.UI.layoutPreview.showProductPreview = true;
      this.UI.showInstructionPane = false;
      // hack to fix corner radius on svg preview becasue we never edit acm, this should be ok.
      // log("UI is end", this.UI.svgPreview);
      // say we are updating cart, but how is it handled? the same.
      this.pdfToolBoxCheck(this.activeImage.FullUrl);
      this.updatePricing();
      this.resizeLayout();
    },
    setConfigData: _.debounce(async function () {
      console.log("setConfigData");
      let jsonData = {};
      let data = await this.fetchConfigData();
      // log("setConfigData return: data", data);  <-- this sometimes throws an error that it is not JSON
      jsonData = JSON.parse(data);
      // log("setConfigData return: JsonData", jsonData);
      this.configData = jsonData;
      console.log("setConfigData", jsonData);

      this.manageProductRules(this.product_type);
    }, 100),
    async fetchConfigData() {
      // #DECORHACK
      if (this.franchise === "Decor") {
        // this.Franchise = 'Wholesale'
        this.franchise = 'NGS'
      }
      // #WebPrintHack
      if (this.franchise === 'WebPrint') {
        // call.Franchise = 'Wholesale'
        this.franchise = 'Wholesale'
      }
      this.activeImage.franchise = this.franchise;
      const url = this.baseUrl + "/Portal/GetProductOptions";
      const { user } = useAuthStore();
      this.activeImage.userId = user.Id;
      this.activeImage.PageId = "upload";
      // log("fetchConfigData", this.activeImage);
      const call = this.activeImage;
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: this.dataPush(call)
      });
      const configData = await responseData.json();
      // log("after fetchConfig activeImage", this.activeImage);
      // log("after fetchConfig UI", this.UI);
      return configData;
    },
    getChoiceText(name, available) {
      let returnValue = name;
      if (available == false) {
        returnValue = returnValue + " (Temporarily Unavailable)";
      }
      return returnValue;
    },
    /*getFilePath(fullUrl, fileName, userId) {
      // File paths are all over the place in c# code with alt ways to extract.
      // We do not store a consistent path here. This is an attempt to fix that.
      // A better approach would be to write the correct path when writing JSON.
  
      // log("getFilePath url: ", fullUrl);
      // log("getFilePath name: ", fileName);
      // log("getFilePath userId: ", userId.toString());
      const lib = "ImgLibrary";
      const stringUrl = fullUrl.toString();
      const urlArray = stringUrl.split("/");
      const _sep = "\\";
      let _serverPath = "";
      // console.log(urlArray);
      _.pull(urlArray, "", lib, userId.toString(), fileName);
      // console.log(urlArray);
      _.forEach(urlArray, function (path) {
        _serverPath = _serverPath + _sep + path;
      });
      _serverPath = _serverPath + _sep + fileName;
      // console.log("serverPath: " + _serverPath);
      return _serverPath;
    },*/
    getHelpText(option) {
      // console.log("getHelpText");
      const helpItems = this.helpText;
      const help = _.find(helpItems, ["name", option]);
      return help.text;
    },
    getPanelImage(index, prop) {
      // log("panel image count", this.UI.acmLayout.panelImageCount);
      // log("getPanelImage: index", index);
      // log("getPanelImage: prop", prop);
      let returnValue = false;
      let current = index;
      let currentSheet = this.UI.acmLayout.currentPreviewSheet;
      // console.log("current: " + current + " currentSheet: " + currentSheet);
      if (this.UI.svgPreview.panelImages.length > 0) {
        returnValue = this.UI.svgPreview.panelImages[currentSheet][current];
      }
      // log("getPanelImage:", returnValue[prop]);
      return returnValue[prop];
    },
    getPanelImageCount() {
      let returnValue = 0;
      if (this.project.imageList.length > 0) {
        returnValue = this.getTotalItems();
        this.setPanelXCount();
        this.setPanelYCount();
        let max = this.UI.svgPreview.panelXCount * this.UI.svgPreview.panelYCount;
        // console.log("panelImageCount max: " + max);
        if (returnValue > max) {
          returnValue = max;
        }
        // log("panelImageCount: " + returnValue);
      }
      return returnValue;
    },
    setPanelPosition(pImages) {
      // log("setPanelPosition", this.UI.svgPreview);
      let _this = this;

      _.forEach(pImages, function (images, pKey) {
        let totalInThisRow = _this.UI.svgPreview.panelXCount;
        let totalColumns = _this.UI.svgPreview.panelYCount;
        let pWidth = _this.UI.svgPreview.width;
        let pHeight = _this.UI.svgPreview.height;
        let panelInRow = 1;
        let columnInPanel = 1;
        _.forEach(images, function (image, iKey) {
          image["xPosition"] = 0;
          image["yPosition"] = 0;
          if (panelInRow > 1) {
            image["xPosition"] = (panelInRow - 1) * pWidth;
          }
          // set column position
          if (columnInPanel > 1) {
            image["yPosition"] = (columnInPanel - 1) * pHeight;
          }
          // Advance the row and column
          if (panelInRow === totalInThisRow) {
            panelInRow = 1;
            columnInPanel++;
          } else {
            panelInRow++;
          }
          // log("working image", image);
        });
      });
      // log("setPanelPostion", pImages);
      return pImages;
    },
    setPanelXCount() {
      // console.log("setPanelXCount");
      if (this.UI.acmLayout.panelWidth > 0) {
        let inWidth = parseFloat(this.UI.acmLayout.panelWidth);
        // log(this.UI.acmLayout);
        let sheetWidth = parseFloat(this.UI.acmLayout.SheetWidth);
        // log("sheetWidth: ", sheetWidth);
        // log("inWidth: ", inWidth);
        let count = sheetWidth / inWidth;
        // log("Panel XCount: ", count);
        this.UI.svgPreview.panelXCount = parseInt(count);
      }
    },
    setPanelYCount() {
      // console.log("setPanelYCount");
      if (this.UI.acmLayout.panelHeight > 0) {
        let inHeight = parseFloat(this.UI.acmLayout.panelHeight);
        let sheetHeight = parseFloat(this.UI.acmLayout.SheetHeight);
        let count = sheetHeight / inHeight;
        // console.log("Panel YCount: " + parseInt(count));
        this.UI.svgPreview.panelYCount = parseInt(count);
      }
    },
    SVGHoleDecoration() {
      // log("svgHoleDecoration");
      // _svgthis = this;
      let holeChoices = this.activeImage.hole_placement.split(",");
      const holes = [];
      // const c2c = parseFloat(this.UI.svgPreview.cToCenter);
      const c2c = parseFloat(this.UI.svgPreview.cToCenter) / 2;
      const c2e = parseFloat(this.UI.svgPreview.cToEdge);
      const width = parseFloat(this.UI.svgPreview.width);
      const height = parseFloat(this.UI.svgPreview.height)
      // 30 x 30 is our optimum text size
      let textSize = "1em";
      if (width >= 30) {
        textSize = parseFloat(height / width) + "em";
      } else {
        textSize = parseFloat(width / 30) + "em";
        if (textSize < .45) {
          textSize = .45;
        }
      }
      // log("textSize: ", textSize);
      this.UI.svgPreview.textSize = textSize;
      const radius = parseFloat(this.UI.svgPreview.hDiameter) / 2;
      _.forEach(holeChoices, function (hole, k) {
        let left1 = 0;
        let left2 = 0;
        let top1 = 0;
        let top2 = 0;
        let c2eLine1x1 = 0;
        let c2eLine1x2 = 0;
        let c2eLine2x1 = 0;
        let c2eLine2x2 = 0;
        let c2eLine1y1 = 0;
        let c2eLine1y2 = 0;
        let c2eLine2y1 = 0;
        let c2eLine2y2 = 0;
        let holecount = 2;
        let dleftx1 = 0;
        let dleftx2 = 0;
        let dtopy1 = 0;
        let dtopy2 = 0;
        let c2eTextX1 = 0;
        let c2eTextY1 = 0;
        let c2eTextX2 = 0;
        let c2eTextY2 = 0;
        let dTextx1 = 0;
        let dTextx2 = 0;
        let dTexty1 = 0;
        let dTexty2 = 0;

        /* Top and Bottom Common */
        if (hole === "top" || hole === "bottom") {
          const center = width / 2;
          left1 = center - c2c;
          left2 = center + c2c;
          // X positions
          c2eLine1x1 = left1;
          c2eLine1x2 = left1;
          c2eLine2x1 = left2;
          c2eLine2x2 = left2;
          dleftx1 = left1;
          dleftx2 = left2;
          c2eTextX1 = left1 - 1.05;
          c2eTextX2 = left2 + .25;

        }
        /* Top and Bottom Unique */
        if (hole === "top") {
          top1 = c2e;
          top2 = c2e;
          // y positions
          c2eLine1y1 = 0;
          c2eLine1y2 = top1;
          c2eLine2y1 = 0;
          c2eLine2y2 = top2;
          dtopy1 = top1;
          dtopy2 = top1;
          c2eTextY1 = top1;
          c2eTextY2 = top2;
        }

        if (hole === "bottom") {
          top1 = height - c2e;
          top2 = height - c2e;
          // y positions
          c2eLine1y1 = height;
          c2eLine1y2 = top1;
          c2eLine2y1 = height;
          c2eLine2y2 = top2;
          dtopy1 = top1;
          dtopy2 = top2;
          c2eTextY1 = top1 + .5;
          c2eTextY2 = top2 + .5;
        }

        // Set distance line text
        if (hole === "top" || hole === "bottom") {
          if (dleftx1 > 0 && dleftx2 > 0) {
            dTextx1 = dleftx1 + ((dleftx2 - dleftx1) / 2);
            dTextx2 = dTextx1;

          }
          if (dtopy1 > 0 && dtopy2 > 0) {
            dTexty1 = dtopy1;
            dTexty2 = dtopy2;
          }
        }
        /* Left and Right Common */
        if (hole === "left" || hole === "right") {
          const center = height / 2;
          top1 = center - c2c;
          top2 = center + c2c;
          c2eLine1y1 = top1;
          c2eLine1y2 = top1;
          c2eLine2y1 = top2;
          c2eLine2y2 = top2;
          dtopy1 = top1;
          dtopy2 = top2;
          c2eTextY1 = top1 - .25;
          c2eTextY2 = top2 - .25;
        }
        /* Left and Right Unique */
        if (hole === "left") {
          left1 = c2e;
          left2 = c2e;
          c2eLine1x1 = 0;
          c2eLine1x2 = left1;
          c2eLine2x1 = 0;
          c2eLine2x2 = left2;
          dleftx1 = left1;
          dleftx2 = left2;
          c2eTextX1 = left1;
          c2eTextX2 = left2;
        }

        if (hole === "right") {
          left1 = width - c2e;
          left2 = width - c2e;
          c2eLine1x1 = width;
          c2eLine1x2 = left1;
          c2eLine2x1 = width;
          c2eLine2x2 = left2;
          dleftx1 = left1;
          dleftx2 = left2;
          c2eTextX1 = left1;
          c2eTextX2 = left2;
        }
        // Set distance line text
        if (hole === "left" || hole === "right") {
          if (dleftx1 > 0 && dleftx2 > 0) {
            dTextx1 = dleftx1;
            dTextx2 = dleftx2;

          }
          if (dtopy1 > 0 && dtopy2 > 0) {
            dTexty1 = dtopy1 + ((dtopy2 - dtopy1) / 2);
            dTexty2 = dTexty1;
          }
        }

        /* Four Corner Holes */
        if (hole === "top_left") {
          holecount = 1
          left1 = c2e;
          top1 = c2e;
        }

        if (hole === "top_right") {
          holecount = 1
          left1 = width - c2e;
          top1 = c2e;
        }

        if (hole === "bottom_left") {
          holecount = 1
          left1 = c2e;
          top1 = height - c2e;
        }

        if (hole === "bottom_right") {
          holecount = 1
          left1 = width - c2e;
          top1 = height - c2e;
        }


        holes.push({
          x: left1,
          y: top1,
          r: radius,
          name: hole + "_1",
          c2eLinex1: c2eLine1x1,
          c2eLinex2: c2eLine1x2,
          c2eLiney1: c2eLine1y1,
          c2eLiney2: c2eLine1y2,
          lineName: hole + "_1_line",
          dlineName: hole + "_1_distance",
          dLinex1: dleftx1,
          dLiney1: dtopy1,
          dLinex2: dleftx2,
          dLiney2: dtopy2,
          c2eTX: c2eTextX1,
          c2eTY: c2eTextY1,
          c2cTX: dTextx1,
          c2cTY: dTexty1,
        });

        if (holecount > 1) {
          holes.push({
            x: left2,
            y: top2,
            r: radius,
            name: hole + "_2",
            c2eLinex1: c2eLine2x1,
            c2eLinex2: c2eLine2x2,
            c2eLiney1: c2eLine2y1,
            c2eLiney2: c2eLine2y2,
            line: hole + "_2_line",
            dlineName: hole + "_2_distance",
            dLinex1: left2,
            dLinex2: left2,
            dLiney1: top2,
            dLiney2: top2,
            c2eTX: c2eTextX2,
            c2eTY: c2eTextY2,
            c2cTX: dTextx2,
            c2cTY: dTexty2,
          });
        }
      });
      return holes;
    },
    SVGDecoration() {
      // log("svgDecoration");
      if (this.product_type === "rigid") {
        if (this.UI.svgPreview.holeSizeError === false) {
          if (this.activeImage.hole_placement !== "Select") {
            if (this.activeImage.hole_edge_distance !== "Select") {
              if (this.activeImage.hole_hole_distance > 0) {
                return this.SVGHoleDecoration();
              }
            }
          }
          // four corners
          // <option className="" value="top_left,top_right,bottom_left,bottom_right">All Four Corners</option>
          if (this.activeImage.hole_placement === "top_left,top_right,bottom_left,bottom_right") {
            return this.SVGHoleDecoration();
          }
        }
      }
    },
    getTotalItems() {
      let totalItems = 0;
      if (this.project.imageList.length > 0) {
        let imageList = this.project.imageList;
        if (imageList.length > 0) {
          _.forEach(imageList, function (pImage, key) {
            let q = pImage.Quantity;
            for (let count = 0; count < q; count++) {
              totalItems++;
            }
          });
        }
      }
      return totalItems;
    },
    hasHelpText(option) {
      // console.log("hasHelpText");
      const helpItems = this.helpText;
      let returnValue = true;
      // help = helpItems.find(el => el == option);
      let help = _.find(helpItems, ["name", option]);
      if (_.isUndefined(help)) {
        returnValue = false;
      }
      return returnValue;
    },
    hideLibrary() {
      // console.log("hideLibrary");
      UIkit.modal("#ManageFilesModal").hide()
    },
    iconFile(ChoiceValue) {
      if (ChoiceValue !== 'loading') {
        // log("iconFile", ChoiceValue);
        let returnValue = "/public/Images/";
        let r = {};
        r = _.find(this.UI.productIcons, ["ChoiceValue", ChoiceValue]);
        if (r) {
          returnValue += r.IconFile;
        }
        // log("iconFile return", returnValue);
        return returnValue;
      }
    },
    initChoiceOptions(myKey) {
      // In Index this populates the select dropdowns with options
      // console.log("initChoiceOptions: " + myKey);
      //console.log(myKey);
      let what = _.filter(this.configData.dtOptionChoices, ['OptionKey', myKey]);
      //console.log(what);
      let sorted = _.sortBy(what, ["ChoiceSort"]);
      //console.log(sorted)
      return sorted
    },
    lockOptionsToAll: _.debounce(function () {
      // console.log("lockOptionsToAll");
      if (this.project.imageList.length > 1) {
        // log("lockOptionsToAll RUN");
        // Going to need _this
        let _this = this;
        let ui;
        ui = _this.UI.lockedOptions[this.product_type.toString()];
        if (ui) {
          // The options we are forcing to be the same across all panels or images.;
          let lockAllOptions = Array.from(ui);
          let optionsList = _.pull(lockAllOptions, "Message");
          // console.log(optionsList);
          _.forEach(_this.project.imageList, function (image, key) {
            _.each(optionsList, function (option) {
              // log("current option: " + option);
              image[option] = _this.activeImage[option];
              // log("current image: ",_this.activeImage);
              // console.log(image);
              // HACK to pull any error related to the option
              _this.pullError(option, image);
              // HACK to get Material text into the image.
              // Populates for ImageCardTemplate display and StoreController logic.
              if (option === "ItemNo") {
                // log("found ItemNo");
                let material = _.find(_this.configData.dtOptionChoices, ['ChoiceValue', image.ItemNo]);
                if (material) {
                  // log("have material");
                  // log("product_type", _this.product_type);
                  if (_this.product_type === "rigid") {
                    let rigidType = _.find(_this.configData.dtOptionChoices, ['ChoiceValue', _this.activeImage.rigid_type]);
                    image.MaterialText = rigidType.ChoiceName + " " + material.ChoiceName;
                    // log("set materialtext to ", image.MaterialText);
                  }
                }
              }
            });
          });
        }
      }
    }, 50),
    manageFinishTypes() {
      // console.log("manageFinishTypes");
      this.pullError("LaminateType");
      if (this.activeImage.hasOwnProperty("ItemNo")) {
        this.checkValidLaminateType();
        let haveLaminate;
        haveLaminate = _.find(this.configData.dtOptions, ["OptionKey", "LaminateType"]);
        // console.log(haveLaminate);
        if (haveLaminate) {
          // Has to be materialList to do this
          let finish = _.find(this.configData.dtOptionChoices, ["ChoiceValue", this.activeImage.LaminateType]);
          // console.log(finish);
          if (this.activeImage.hasOwnProperty("LaminateType")) {
            if (finish) {
              this.activeImage.FinishText = finish.ChoiceName;
              this.pullError("LaminateType");
            }
          }

          if (this.activeImage.LaminateType === "Select" || this.activeImage.LaminateType === "") {
            this.pushError("LaminateType");
          }
        }
      }
    },
    checkValidLaminateType() {
      // log("checkValidLaminateType")
      if (this.product_type !== "rigid") {
        const _choseLaminate = this.activeImage.LaminateType;
        // log("current choice", _choseLaminate)
        let what = _.filter(this.configData.dtOptionChoices, ['OptionKey', 'LaminateType']);
        // log("reset lam", what);
        let found = _.findIndex(what, ['ChoiceValue', _choseLaminate]);
        if (found == 1) {
          // log("FOUND");
        }
        if (found == -1) {
          // log("LOST");
          // log("Reset Laminate");
          // this.activeImage.LaminateType = "Select";
        }
      }
    },
    manageOverlap() {
      // console.log("manageOverlap");
      // console.log(this.activeImage);
      // going to need _this;
      let _this = this;
      _this.UI.Overlap = false;
      _this.pullWarning('Overlap');
      _this.pullError("TooWideWindow");
      _this.pullCartError("TooWideWindow");
      let placeholder = "";
      // May 25, 2023 Travis changed BE code to send Overlap size from Roll size, instead of a fixed 59
      let overlapData = _.filter(_this.configData.dtOptionCondition, ['OptionKey', 'Overlap']);
      let tooWide = _.filter(_this.configData.dtOptionCondition, ['OptionKey', 'TooWide']);
      // log("current overlap data", overlapData);
      // log("tooWide is ", tooWide);
      if (overlapData.length > 0) {
        _this.UI.Overlap = _.every(overlapData, function (ov) {
          // log("activeImage: ", _this.activeImage[ov.OtherOptionKey]);
          // log("conditionValue: ", parseFloat(ov.ConditionValue));
          placeholder = ov.ConditionValue;
          return parseFloat(_this.activeImage[ov.OtherOptionKey]) > parseFloat(ov.ConditionValue);
        });
        // log("Both True? ", _this.UI.Overlap);
      }
      // Make errors specific.
      if (_this.UI.Overlap === true) {
        _this.pushWarning('Overlap', placeholder);
        if (_this.activeImage.Overlap === 'Select') {
          // console.log("forcing overlap to .25");
          _this.activeImage.Overlap = '0.25';
        }
      } else {
        _this.pullWarning('Overlap', placeholder);
      }
      if (tooWide.length > 0) {
        var tooWideValue = _.every(tooWide, function (ov) {
          return parseFloat(_this.activeImage[ov.OtherOptionKey]) > parseFloat(ov.ConditionValue);
        });
        if (tooWideValue) {
          _this.pushError("TooWideWindow");
          _this.pushCartError("TooWideWindow");
        }
      }
      //console.log("showOverlap is: " + _this.showOverlap);
      // Reset overlap to Select if we have cleared it.
      if (_this.UI.Overlap === false) {
        _this.activeImage.Overlap = 'Select'
      }
    },
    manageProductRules(product) {
      // log("manageProductRules", product);
      // This is an object literal with a default return type.
      let _this = this;
      //console.log(this.activeImage);
      let defaults = {
        'adhesive': function () {
          _this.manageOverlap();
          _this.manageFinishTypes();
          _this.updatePricing();
        },
        'Wallcoverings': function () {
          _this.manageOverlap();
          _this.manageFinishTypes();
          _this.updatePricing();
        },
        'Window Decoration': function () {
          _this.manageOverlap();
          _this.manageFinishTypes();
          _this.updatePricing();
        },
        'Magnetic': function () {
          _this.manageOverlap();
          _this.manageFinishTypes();
          _this.updatePricing();
        },
        'rigid': function () {
          _this.checkForCorex();
          _this.setPanelImages();
          _this.updatePricing();
          // Set max panel size based on size choices
          // DO NOT use sheet size because of clear coat and other constraints.
          // let sizes = _.filter(_this.configData.dtOptionChoices, ["OptionKey", "size"]);
          // let sizes = _.filter(_this.configData.dtOptionChoices, ["OptionKey", "MaterialSheetSize"]);
          // if (sizes.length > 0) {
          //     let sorted = _.sortBy(sizes, ["ChoiceSort"]);
          //     let choice = sorted.pop();
          //     let choiceValue = choice["ChoiceValue"];
          //     let max = choiceValue.split(",");
          //     // log("finding maxPanelHeight", max);
          //     _this.UI.acmLayout.panelMaxWidth = max[1];
          //     _this.UI.acmLayout.panelMaxHeight = max[0];
          //     // if using sheet size
          //     // _this.UI.acmLayout.panelMaxWidth = max[0];
          //     // _this.UI.acmLayout.panelMaxHeight = max[1];
          //     // if (_this.activeImage.hasOwnProperty("Rotate")) {
          //     //     if (_this.activeImage.Rotate == "yes") {
          //     //         _this.UI.acmLayout.panelMaxWidth = max[1];
          //     //         _this.UI.acmLayout.panelMaxHeight = max[0];
          //     //     }
          //     // }
          // }
          // May 23 2023 DO USE SHEET SIZE and ignore other constraints because someone tried to order
          // a custom cut 120 x 60 inch panel.
          // log("manage product rules rigid image", _this.activeImage);
          let sizes = _this.activeImage.MaterialSheetSize;
          if (sizes) {
            let max = sizes.split(",");
            // log("manage product rules max size is ", max);
            _this.UI.acmLayout.panelMaxWidth = max[0];
            _this.UI.acmLayout.panelMaxHeight = max[1];
            if (_this.activeImage.hasOwnProperty("Rotate")) {
              if (_this.activeImage.Rotate == "yes") {
                _this.UI.acmLayout.panelMaxWidth = max[1];
                _this.UI.acmLayout.panelMaxHeight = max[0];
              }
            }
          }
        },
        // no product or blank string.
        '': function () {
          // log("manageProductRules no product");
          let searchParams = new URLSearchParams(window.location.search);
          let cartId = searchParams.get("editid");
          if (cartId) {
            _this.editMyCartItem(cartId);
          }
          if (!cartId) {
            // UIkit.modal("#ProductListModal").show();
          }
        }
      }
      return (defaults[product] || defaults['none']
      )
      ();
      //return defaults[product]();
    },
    restartPortal() {
      this.$router.go(0);
    },
    resizeLayout: _.debounce(function () {
      //code resizeadhesive, resizeacm, etc. method and call them here
      // log("resizeLayout");
      // need default of 1 to avoid errors in svg
      let widthInInches = 1;
      let heightInInches = 1;
      // console.log("Getting preview based on image size.")
      // Being defensive about nan and divide by 0
      if (this.activeImage.hasOwnProperty('InchWidth')) {
        // log("resizeLayout", this.activeImage);
        widthInInches = this.activeImage.InchWidth;
        if (_.isUndefined(widthInInches) || widthInInches === "0" || widthInInches === 0) {
          widthInInches = 1;
        }
      }
      if (this.activeImage.hasOwnProperty('InchHeight')) {
        heightInInches = this.activeImage.InchHeight;
        if (_.isUndefined(heightInInches) || heightInInches === "0" || heightInInches === 0) {
          heightInInches = 1;
        }
      }

      // only show the pane if we have an image.
      // prevents cross hairs forming.
      if (this.activeImage.hasOwnProperty('Id')) {
        this.UI.layoutPreview.showProductPreview = true;
        this.UI.showInstructionPane = false;
      }

      // Activate ACM Sheet preview instead.
      if (this.product_type === "rigid") {
        // console.log("ACM Switching calculation method when we have a size.");
        this.UI.layoutPreview.showProductPreview = true;
        this.UI.layoutPreview.showSheetPreview = true;
        if (this.activeImage.size !== "Select") {
          // console.log("ACM we have activeImage size");
          widthInInches = this.UI.acmLayout.panelWidth;
          heightInInches = this.UI.acmLayout.panelHeight;
        }
      }
      // heightRatio = widthInInches / heightInInches;
      // this.UI.layoutPreview.heightRatio = heightRatio;
      // Set properties for calculated properties.
      // log("resizeLayout widthInInches", widthInInches);
      this.UI.layoutPreview.widthInInches = widthInInches;
      this.UI.layoutPreview.heightInInches = heightInInches;
      this.UI.svgPreview.width = widthInInches;
      this.UI.svgPreview.height = heightInInches;
      // log("should have preview image");
      if (this.product_type === "rigid") {
        this.setPanelImages();
      }
    }, 300),
    selectActiveImage(index) {
      //console.log("selectActiveImage index: " + index);
      this.activeImage = this.project.imageList[index];
      this.UI.selectedImage = this.activeImage.Id;
      this.checkForImageErrors();
      //this.debugImageList()
      this.resizeLayout();
    },
    selectProduct(product) {
      // Sets the product_type which triggers another dataConfig update.
      // product_type is also used in other logic.
      // log("selectProduct: ", product);
      // UIkit.modal("#ProductListModal").hide();
      if (product === "misc") {
        router.push({ name: 'misc' });
      } else {
        this.product_type = product;
        this.activeImage.product_type = product;
        if (product.length > 0) {
          this.UI.showInstructionPane = false;
          this.UI.showRestartPortal = true;
          this.UI.libraryActive = true;
          this.setConfigData();
        }
      }
    },
    selectProductType(PC) {
      // enables and disables selected icon color and attributes.
      //console.log("selectProductType method");
      let returnValue = false;
      if (this.product_type.length > 1) {
        if (PC === this.product_type) {
          returnValue = true;
        }
      }
      return returnValue;
    },
    showPreviewSheet(index) {
      // console.log("Go to sheet: " + index);
      this.UI.acmLayout.currentPreviewSheet = index;
    },
    hideProductType(PC) {
      let returnValue = false;
      if (this.product_type.length > 1) {
        if (PC != this.product_type) {
          returnValue = true;
        }
      }
      return returnValue;
    },
    setPanelImages() {
      if (this.activeImage.size != "Select") {
        // log("setPanelImages");
        let _this = this;
        let imageList = this.project.imageList;
        // log("imageList", imageList);
        let maxOnPanel = _this.getPanelImageCount();
        this.UI.acmLayout.panelImageCount = maxOnPanel;
        // console.log("maxOnPanel: " + maxOnPanel);
        let imageCount = _this.getTotalItems();
        // console.log("imageCount: " + imageCount);
        let sheetCount = Math.ceil(imageCount / maxOnPanel);
        this.UI.acmLayout.previewSheetCount = sheetCount;
        // console.log("sheetCount: " + sheetCount);
        let tImages = [];
        let pImages = [];
        // currentSheet = 1;
        let currentOnCount = 0;
        if (imageList.length > 0) {
          _.forEach(imageList, function (pImage, key) {
            let q = pImage.Quantity;
            // log("quantity for count", q);
            for (let count = 1; count <= q; count++) {
              let tt = {};
              tt["base64"] = pImage.base64String;
              tImages.push(tt);
              currentOnCount++
              // log("currentOnCount", currentOnCount);
              // log("maxOnPanel", maxOnPanel);
              if (currentOnCount === maxOnPanel) {
                pImages.push(tImages);
                tImages = [];
                currentOnCount = 0;
              }
            }
          });
          pImages.push(tImages);
        }
        // log("panelImages", pImages);
        pImages = this.setPanelPosition(pImages);
        this.UI.svgPreview.panelImages = pImages;
      }
    },
    showApplyAll(key) {
      // console.log("showApplyAll: " + key);
      let returnValue = false;
      // console.log(this.product_type);
      // ui = this.configData.dtOptions
      const ui = this.UI.applyAllOptions[this.product_type.toString()];
      // console.log(ui);
      if (ui) {
        // r = _.find(ui, ["OptionKey", key]);
        const r = ui.find(e => e == key);
        const count = this.project.imageList.length;
        if (count > 1) {
          // if(key === "Button") {
          //     returnValue = true;
          // }
          if (r) {
            // returnValue = ui.AllowApplyAll;
            returnValue = true;
          }
        }
      }
      // console.log("returnValue: " + returnValue);
      return returnValue;
    },
    showImageLibrary() {
      // console.log("showImageLibrary");
      // Loads the image library dialog. Dialog was populated on page load.
      // See the PortalModals and UploadModal.
      if (this.UI.libraryActive) {
        UIkit.modal("#ManageFilesModal").show();
      }
    },
    showLockedOption(key) {
      //console.log("showLockedOption: " + key);
      let returnValue = false;
      const ui = this.UI.lockedOptions[this.product_type.toString()];
      //console.log(ui)
      if (ui) {
        const r = ui.find(e => e == key);
        const count = this.project.imageList.length;
        if (count > 1) {
          if (r) {
            returnValue = true;
          }
        }
      }
      if (returnValue === true) {
        this.lockOptionsToAll();
      }
      return returnValue;
    },
    pullError(error, img) {
      // Removes errors from the users error list
      // log("pullError error: ", error);
      //console.log(this.errorList);
      // log("pullError image:", img);
      let currentImage = this.activeImage;
      if (img) {
        currentImage = img;
      }
      //console.log(currentImage);
      let item = _.find(this.errorTypes, ['name', error]);
      //console.log(item);
      if (item) {
        _.pull(currentImage.errorList, '<strong>' + currentImage.Name + '</strong>' + item.text);
        // $('#' + currentImage.Id).removeClass('alert-danger');
      }
      // this.showErrorList();
    },
    pullWarning(error, placeholder, img) {
      // Removes warnings from the users warning list
      //console.log("pullWarning: " + error);
      let currentImage = this.activeImage;
      if (img) {
        currentImage = img;
      }
      var item = _.find(this.errorTypes, ['name', error]);
      //console.log(item);
      if (item) {
        let s = item.text;
        if (placeholder) {
          s = s.replace(/{placeholder}/g, placeholder);
        }
        _.pull(currentImage.warningList, '<strong>' + currentImage.Name + '</strong>' + s);
      }
      // this.showErrorList();
    },
    pushError(error, img) {
      // Adds errors to the users error list.
      // modified to assign errors to images, so they don't get cleared on image change.
      //console.log("pushError: " + error);
      // log("pushError", img);
      let currentImage = this.activeImage;
      if (img) {
        //console.log("GRROOOTT");
        currentImage = img;
      }
      let item = _.find(this.errorTypes, ['name', error]);
      if (item) {
        let s = item.text;
        //console.log("pushing error: " + s);
        currentImage.errorList.push('<strong>' + currentImage.Name + '</strong>' + s);
        // FRN manage errors
        // $('#' + currentImage.Id).addClass('alert-danger');

      }
      // this.showErrorList();
    },
    pushWarning(error, placeholder, img) {
      // Adds Warnings to the users Warning list.
      // log("pushWarning: ", error);
      let currentImage = this.activeImage;
      if (img) {
        currentImage = img;
      }
      var item = _.find(this.errorTypes, ['name', error]);
      if (item) {
        let s = item.text;
        if (placeholder) {
          s = s.replace(/{placeholder}/g, placeholder);
        }
        //TODO - duplicate warnings being added every time overlap option change
        var errorAlreadyPushed = false;
        var errorString = '<strong>' + currentImage.Name + '</strong>' + s
        for (let i = 0; i < currentImage.warningList.length; i++) {
          if (currentImage.warningList[i] === errorString) {
            errorAlreadyPushed = true;
          }
        }
        if (!errorAlreadyPushed) {
          currentImage.warningList.push(errorString);
        }
      }
      // this.showErrorList();
    },
    pushCartError(error) {
      // Error list just for the cart.
      // console.log("pushCartError: " + error);
      // var item = _.find(this.errorTypes, ['name', error]);
      // if (item) {
      this.cart.errors = this.cart.errors + error + '<br>';
      // }
    },
    pullCartError(error) {
      // Error list just for the cart.
      // console.log("pullCartError: " + error);
      var item = _.find(this.errorTypes, ['name', error]);
      if (item) {
        _.pull(this.cart.errors, item.text);
      }
    },
    reorderList() {
      // When deleting items from the project image list we need to reset the keys.
      // console.log('reorderList');
      // going to need _this;
      let _this = this;
      // set LineNumber to match the new index key.
      _.each(this.project.imageList, function (image, key) {
        //console.log(image);
        image.LineNumber = key;
        _this.project.imageList[key] = image;
      });
      //console.log('after reorder');
      //this.debugImageList();
    },
    syncImageList() {
      //console.log("syncImageList");
      if (this.activeImage.hasOwnProperty('Name')) {
        let indexOfImage = this.activeImage.LineNumber;
        this.project.imageList[indexOfImage] = this.activeImage;
      }
    },
    dataClean(dataObject) {
      _.forIn(dataObject, function (value, key) {
        // log("dataPush key ", key);
        // log("dataPush value ", value);
        switch (key) {
          case 'Overlap':
            // log("changing overlap", value);
            if (value === "Select") {
              value = 0;
            }
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 0;
              }
            }
            if (_.isNil(value)) {
              value = 0;
            }
            // log("changed overlap ", value)
            dataObject[key] = parseFloat(value);
            break;
          case 'InchHeight':
            // log("changing InchHeight");
            dataObject[key] = parseFloat(value);
            break;
          case 'InchWidth':
            // log("changing InchWidth ", value);
            dataObject[key] = parseFloat(value);
            break;
          case 'SheetHeight':
            // log("changing SheetHeight ", value.length);
            // log("changing SheetHeight ", _.isString(value));
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 1;
              }
            }
            if (_.isNil(value)) {
              value = 1;
            }
            dataObject[key] = parseFloat(value);
            break;
          case 'SheetWidth':
            // log("changing SheetWidth ", value.length);
            // log("changing SheetWidth ", _.isString(value));
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 1;
              }
            }
            if (_.isNil(value)) {
              value = 1;
            }
            dataObject[key] = parseFloat(value);
            break;
          case 'CustomerNumber':
            // log("changing CustomerNumber");
            dataObject[key] = parseInt(value);
            break;
          case 'CartID':
            // log("changing CartID ", value.length);
            // log("changing CartID ", _.isString(value));
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 0;
              }
            }
            if (_.isNil(value)) {
              value = 0;
            }
            dataObject[key] = parseInt(value);
            break;
          case 'SetId':
            // log("changing SetId ", value.length);
            // log("changing SetId ", _.isString(value));
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 0;
              }
            }
            if (_.isNil(value)) {
              value = 0;
            }
            dataObject[key] = parseInt(value);
            break;
          case 'Quantity':
            // log("changing Quantity ", value.length);
            // log("changing Quantity ", _.isString(value));
            if (_.isString(value)) {
              if (value.length === 0) {
                value = 0;
              }
            }
            if (_.isNil(value)) {
              value = 0;
            }
            dataObject[key] = parseInt(value);
            break;
          case 'Price':
            // log("changing Price ", value);
            // log("changing Price ", value.length);
            if (_.isString(value)) {
              if (value.length === 0)
                  // log("price is null make it 0");
                value = 0;
            }
            dataObject[key] = parseFloat(value);
            break;
          case 'base64String':
            dataObject[key] = "";
            break;
          default:
            // do nothing;
        }
      });
      return dataObject;
    },
    dataPush(inData) {
      // FRN with all the work on JSON properties, can this and dataClean go away?
      // Why assign was not making a copy and passing reference I do not know
      // let xInData = Object.assign(inData);
      // This seems like a hack, but I at least preserve base64String
      let xInData = JSON.parse(JSON.stringify(inData));
      if (Array.isArray(xInData)) {
        let _this = this;
        _.forEach(xInData, function (current, index) {
          xInData[index] = _this.dataClean(current);
        });
      } else {
        xInData = this.dataClean(xInData);
      }
      return JSON.stringify(xInData);
    },
    updatePricing: _.debounce(function () {
      // console.log("updatePricing");
      // console.log(this.activeImage);
      const url = this.baseUrl + "/Store/GetPricing";
      const { user } = useAuthStore();
      this.syncImageList();
      // this.debugImageList();
      // we need _this.
      let _this = this;
      let data = [];

      // checking to see if we have an active image, and changing cartButtonText based on having a list.
      if (_this.cart.updateCart) {
        _this.cart.cartButtonText = "Update Cart"
      }
      if (_this.activeImage.hasOwnProperty('Name')) {
        let indexOfImage = this.activeImage.LineNumber;
        _this.project.imageList[indexOfImage] = _this.activeImage;
        if (_this.project.imageList.length > 0) {
          _this.cart.cartButtonText = "Add To Cart"
        }
      }
      // console.log("showNoImagePrice property: " + _this.UI.showNoImagePrice);
      //no image but they want to check pricing
      if (_this.UI.showNoImagePrice === true) {
        // console.log("Active Image for showNoImagePrice")
        _this.activeImage.Quantity = 1;
        _this.activeImage.LineNumber = 0;
        _this.activeImage.product_type = this.product_type;
        // false becomes "Fit" true becomes "Stretch" in bullseye
        _this.activeImage.StretchCenter = false;
        data.push(_this.activeImage);
      }

      if (_this.UI.showNoImagePrice === false) {
        data = _this.project.imageList;
      }
      // log("Data for pricing: ", data);
      // FRN needs moved to fetch from jquery
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: _this.dataPush(data)
      }).then(returnedData => {
        // log("update pricing response", returnedData);
        return returnedData.json();
      }).then((jsondata) => {
        let response = jsondata;
        // log("pricing json response", response.Data);
        if (response.Status === "ok") {
          let _grand = 0.00;
          let _sqFtTotal = 0.00;
          let _projQuantity = 0;
          _.forEach(response.Data, function (price, linenumber) {
            let index = linenumber
            // log('response pricing linenumber ', linenumber);
            // console.log('UI.showNoImagePrice ' + _this.UI.showNoImagePrice);
            if (_this.UI.showNoImagePrice === false) {
              let row = _this.project.imageList[index];
              if (row !== undefined) {
                // log("price: ", price);
                row.Price = parseFloat(price.Total);
                row.SquareFeet = price.SquareFeet;
                _grand = _grand + parseFloat(price.Total);
                _sqFtTotal = _sqFtTotal + parseFloat(price.SquareFeet);
                _projQuantity = _projQuantity + parseInt(row.Quantity);
              }
            }

            if (_this.UI.showNoImagePrice === true) {
              _grand = parseFloat(price.Total);
              _sqFtTotal = parseFloat(price.SquareFeet);
              // _projQuantity = _projQuantity + parseInt(row.Quantity);
              _projQuantity = 1
            }

            if (_this.product_type === "rigid") {
              _sqFtTotal = parseFloat(price.SquareFeet);
            }
            _this.project.grandTotal = _grand.toLocaleString("en-US", {
              style: "currency",
              currency: "USD"
            }); //c
            _this.project.sqFtTotal = _sqFtTotal;
            _this.project.projectTotal = _grand;
          });
        }
      });
    }, 500),
    updateQuantity(index, $event) {
      // console.log("updateQuantity");
      // console.log($event.target.value);
      let value = $event.target.value;
      // Don't allow 0 quantity to delete an object.
      if (value < 1) {
        value = 1;
      }
      this.activeImage.Quantity = value;
      this.updatePricing();
      this.setPanelImages();
      this.quantity = value;
    },
    openMisc() {
      router.push({ name: 'misc' });
    },
    showMinCharge() {
      let returnValue = false;
      if (this.isFranchiseEnabled('minPricing')) {
        if (parseFloat(!this.project.grandTotal || this.project.grandTotal.substring(1, this.project.grandTotal.length)) < 150) {
          returnValue = true;
        }
      }
      return returnValue;
    }
  },
  watch: {
    addedImage(newImage, oldImage) {
      // log("addedImage newImage is: ", newImage);
      if (_.isUndefined(newImage)) {
        // console.log("addedImage: undefined image")
        this.pushError("MissingLibraryItem");
        this.hideLibrary();
        // this.showErrorList();

      }
      if (newImage) {
        // console.log("addedImage: newImage")
        this.pullError("MissingLibraryItem");
        this.addImageToProject(newImage);
        this.hideLibrary();
        this.updatePricing();
      }
    },
    "activeImage.hole_edge_distance"(newVal, oldVal) {
      // returns edge based on hole/inch Verital spacing for horizontal holes
      //log("svgHoleEdge");
      //sets a default safe value
      this.UI.svgPreview.cToEdge = 1;
      if (this.product_type === "rigid") {
        if (this.activeImage.hole_edge_distance !== "Select") {
          let distance = this.activeImage.hole_edge_distance;
          this.UI.svgPreview.cToEdge = parseFloat(distance);
        }
      }
    },
    "activeImage.hole_hole_distance"(newVal, oldVal) {
      // log("watch hole_hole_distance: " + newVal);
      // sets a default safe value
      this.checkHolePlacement(newVal);
    },
    "activeImage.ItemNo"(newVal, oldVal) {
      // log("watch activeImage.ItemNo");
      // When ItemNo changes we reset all errors because they can be tied to product types.
      if (this.product_type !== "rigid") {
        this.clearErrors();
        this.clearWarnings();
      }
      let material = false;
      //console.log(this.configData);
      let optionChoices = this.configData.dtOptionChoices;
      //console.log(optionChoices);
      material = _.find(optionChoices, ['ChoiceValue', newVal]);
      // console.log(material);
      if (material) {
        // If we have a material, we need to fetch again.
        this.setConfigData();
        // Create the display text for the image library.

        if (this.activeImage.hasOwnProperty('ItemNo')) {
          this.activeImage.MaterialText = material.ChoiceName;
          if (this.product_type == "rigid") {
            let rigidType = _.find(optionChoices, ['ChoiceValue', this.activeImage.rigid_type]);
            this.activeImage.MaterialText = rigidType.ChoiceName + " " + material.ChoiceName;
            this.lockOptionsToAll();
          }
          // manage error display
          // this.pullError('ItemNo');
        }
        this.updatePricing();
        this.checkForImageErrors();
      }
      if (this.activeImage.ItemNo === "Select" || this.activeImage.ItemNo === "") {
        //console.log("no material");
        this.pushError('ItemNo');
      }
    },
    "activeImage.LaminateType"(newVal, oldVal) {
      //console.log("LaminateType watch property: " + newVal);
      // console.log(newVal);
      this.pullError('LaminateType');
      var haveLaminate;
      haveLaminate = _.find(this.configData.dtOptions, ["OptionKey", "LaminateType"]);
      // console.log(haveLaminate);
      if (haveLaminate) {
        // Has to be materialList to do this
        let finish = _.find(this.configData.dtOptionChoices, ['ChoiceValue', newVal]);
        // console.log(finish);
        if (this.activeImage.hasOwnProperty('LaminateType')) {
          // log("Finish: ", finish);
          if (finish) {
            this.activeImage.FinishText = finish.ChoiceName;
          }
        }
        if (this.activeImage.LaminateType === "Select") {
          // log("lamType: ", this.activeImage.LaminateType);
          this.pushError('LaminateType');
        }
      }
      if (this.product_type === "rigid") {
        this.lockOptionsToAll();
      }
      this.updatePricing();
    },
    "activeImage.Overlap"(newVal, oldVal) {
      // console.log("activeImage.Overlap watch")
      this.updatePricing();
    },
    "activeImage.rigid_type"(newVal, oldVal) {
      if (this.product_type === "rigid") {
        if (this.activeImage.hasOwnProperty("rigid_type")) {
          let rigid_text = _.find(this.configData.dtOptionChoices, ['ChoiceValue', newVal]);
          if (rigid_text) {
            this.activeImage.RigidText = rigid_text.ChoiceName;
          }

        }
        this.lockOptionsToAll();
      }
    },
    "activeImage.InchWidth":
        _.debounce(function (newWidth, oldWidth) {
          // log("activeImage.InchWidth watch", newWidth);
          if (this.product_type !== "rigid") {
            if (newWidth > 0 && this.activeImage.InchHeight > 0) {
              this.updatePricing();
              // this call refreshes the preview.
              if (this.activeImage.hasOwnProperty('InchWidth')) {
                this.manageOverlap();
                this.resizeLayout();
              }
            }
          }
          if (this.product_type === "rigid") {
            // Need to debuounce this setting for width and height. It triggers to fast
            if (this.activeImage.size === "custom") {
              //console.log("acm size is custom");
              if (this.activeImage.hasOwnProperty('InchWidth')) {
                this.UI.acmLayout.panelWidth = this.activeImage.InchWidth;
                if (parseFloat(this.activeImage.InchWidth) > 0 && parseFloat(this.activeImage.InchWidth) <= parseFloat(this.UI.acmLayout.SheetWidth)) {
                  this.updatePricing();
                }
              }
              this.lockOptionsToAll();
              this.resizeLayout();
            }
          }
          this.checkForImageErrors();
        }, 150),
    "activeImage.InchHeight":
        _.debounce(function (newHeight, oldHeight) {
          // log("activeImage.InchHeight watch")
          if (this.product_type !== "rigid") {
            if (newHeight > 0 && this.activeImage.InchWidth > 0) {
              this.updatePricing();
              // this call refreshes the preview.
              if (this.activeImage.hasOwnProperty('InchHeight')) {
                this.manageOverlap();
                this.resizeLayout();
              }
            }
          }

          if (this.product_type === "rigid") {
            if (this.activeImage.size === "custom") {
              //console.log("acmsize is custom");
              if (this.activeImage.hasOwnProperty('InchHeight')) {
                this.UI.acmLayout.panelHeight = this.activeImage.InchHeight;
                if (parseFloat(this.activeImage.InchHeight) > 0 && parseFloat(this.activeImage.InchHeight) <= parseFloat(this.UI.acmLayout.SheetHeight)) {
                  this.updatePricing();
                }
              }
              this.lockOptionsToAll();
              this.resizeLayout();
            }
          }
          this.checkForImageErrors();
        }, 150),
    "activeImage.sides"(newValue, oldValue) {
      // console.log("activeImage.sides watch")
      if (this.product_type === "rigid") {
        if (newValue) {
          this.lockOptionsToAll();
        }
      }
    },
    "activeImage.size"(newSize, oldSize) {
      // *** NOTICE Size comes in a value pair of h,w. Material sheet size is w,h
      // log("activeImage.size watch")
      // console.log(newSize);
      if (this.product_type === "rigid") {
        if (newSize) {
          if (newSize !== "custom" && newSize !== "Select") {
            let sizeArray = newSize.split(",");
            this.UI.acmLayout.panelHeight = parseFloat(sizeArray[0]);
            this.UI.acmLayout.panelWidth = parseFloat(sizeArray[1]);
          }
          if (newSize === "custom") {
            this.UI.acmLayout.panelHeight = this.activeImage.InchHeight;
            this.UI.acmLayout.panelWidth = this.activeImage.InchWidth;
          }
          this.checkForImageErrors();
          this.lockOptionsToAll();
          this.resizeLayout();
        }
      }
      //this.debugImageList();
    },
    "activeImage.MaterialSheetSize"(newSize, oldSize) {
      // *** NOTICE MaterialSheetSize comes in a value pair of w,h Size is h,w
      // log("activeImage.MaterialSheetSize watch")
      // console.log(newSize);
      if (this.product_type === "rigid") {
        if (newSize) {
          if (newSize !== "custom" && newSize !== "Select") {
            let sizeArray = newSize.split(",");
            this.UI.acmLayout.SheetWidth = parseFloat(sizeArray[0]);
            this.UI.acmLayout.SheetHeight = parseFloat(sizeArray[1]);
            // handle a rotated panel
            if (this.activeImage.Rotate === "yes") {
              this.UI.acmLayout.SheetWidth = parseFloat(sizeArray[1]);
              this.UI.acmLayout.SheetHeight = parseFloat(sizeArray[0]);
            }
            this.activeImage.SheetWidth = this.UI.acmLayout.SheetWidth;
            this.activeImage.SheetHeight = this.UI.acmLayout.SheetHeight;
          }
          this.setPanelImages();
          this.checkForImageErrors();
          this.lockOptionsToAll();
          this.resizeLayout();
        }
      }
      //this.debugImageList();
    },
    "activeImage.round_corners"(newVal, oldVal) {
      // log("activeImage.round_corners watch")
      let returnValue = 0.0;
      if (newVal !== "Select" && newVal !== "") {
        returnValue = newVal;
      }
      // log("watch round_corners returns", returnValue);
      this.UI.svgPreview.cornerRadius = returnValue;
    }
    ,
    /*"activeImage.base64String"(newVal, oldVal) {
    log("base64String is being changed: ", newVal);
    log("base64String was: ", oldVal);
  },*/
    "activeImage.cut_finish"(newVal, oldVal) {
      // log("activeImage.cut_finish watch")
      if (newVal !== "Select") {
        this.pullError("cut_finish");
      }
      if (this.activeImage.hasOwnProperty('cut_finish')) {
        this.activeImage.CADEmbedded = false;
        this.activeImage.HasCad = false;

        if (newVal === 'embedded') {
          // console.log("cut_finish is embedded");
          this.activeImage.HasCad = true;
          this.activeImage.CADEmbedded = true;
          this.activeImage.ContourCutFile = 'embedded';
          this.checkCutfile();
        }
        if (newVal === 'square') {
          // console.log("cut_finish is square");
          this.activeImage.HasCad = false;
          this.activeImage.CADEmbedded = false;
          this.activeImage.ContourCutFile = '';
          this.pullError("CadCutErrorAdhesive");
          this.pullError("CadCutErrorRigid");
          this.pullError("CadCutErrorCorex");
        }
      }
      this.updatePricing();
    },
    "activeImage.Rotate"(newVal, oldVal) {
      // log("watch rotate", newVal);
      if (this.product_type === "rigid") {
        if (this.activeImage.hasOwnProperty("Rotate")) {
          // Lock to all
          this.lockOptionsToAll();
          let size = this.activeImage.MaterialSheetSize;
          let ss = size.split(",");
          // Rotate Preview
          if (newVal === "yes") {
            // log("switching to horizontal", ss);
            //wrong
            this.UI.svgPreview.sheetSize = "width: 20vh; height: 40vh";
            //right
            // this.UI.svgPreview.sheetSize = "width: 40vh; height: 20vh";
            this.UI.acmLayout.SheetWidth = parseFloat(ss[1]);
            this.UI.acmLayout.SheetHeight = parseFloat(ss[0]);
            this.pullWarning("HorizontalFlute");
          }
          if (newVal === "no") {
            // wrong
            this.UI.svgPreview.sheetSize = "width: 40vh; height: 20vh";
            // right
            // this.UI.svgPreview.sheetSize = "width: 20vh; height: 40vh";
            this.UI.acmLayout.SheetWidth = parseFloat(ss[0]);
            this.UI.acmLayout.SheetHeight = parseFloat(ss[1]);
            // warn corex uses about use of step stakes.
            if (this.activeImage.rigid_type === "corex") {
              this.pushWarning("HorizontalFlute");
            }
          }
          this.setPanelImages();
          this.updatePricing();
        }
      }
    },
  }
}
</script>
<!-- <template src="./VuePortalTemplate.html"></template> -->
<template>
  <!--Project Layout -->
  <section class="uk-section uk-section-xsmall uk-padding-remove-top uk-margin-large-top uk-position-z-index">
    <div class="uk-container uk-container-expand">
      <div class="uk-grid-collapse" uk-grid>
        <!--Project Image list -->
        <div id="ProjectLibrary" class="project-library uk-width-1-4@m uk-text-center">
          <h3 class="uk-h3">Project</h3>
          <div id="ImageCardLibrary" class="uk-margin-bottom">
            <div is="vue:project-item" v-on:deleteimage="deleteFromProject" v-on:selectimage="selectActiveImage"
                 v-on:changecount="updateQuantity" v-bind:images="project.imageList" v-bind:ui="UI"></div>
          </div>
          <div id="ImageAddToProject" class="uk-margin-top" v-on:click="showImageLibrary">
            <div class="uk-text-center">
              <div v-if="this.product_type == ''" class="uk-text">
                <i class="far fa-images fa-2x"></i>
                <p>{{ libraryButtonText }}</p>
              </div>
              <a v-else="" class="uk-link">
                <i class="far fa-images fa-2x"></i>
                <p>{{ libraryButtonText }}</p>
              </a>
            </div>
          </div>
        </div>
        <!-- end left side image library  -->
        <!--Project Preview -->
        <div id="LayoutPane" class="uk-width-3-4@m uk-cover-container">
          <div class="uk-position-relative uk-container product-navigation">
            <div>
              <div class="uk-child-width-auto uk-text-center uk-margin-small-top" uk-grid>
                <template v-for="icon in productOptions" :key="icon.ChoiceValue">
                  <div v-bind:id="icon.OptionKey + '_' + icon.ChoiceValue" class="product-selection"
                       v-on:click="selectProduct(icon.ChoiceValue)"
                       v-bind:class="{ 'uk-active': selectProductType(icon.ChoiceValue), 'uk-hidden': hideProductType(icon.ChoiceValue) }">
                    <img class="product-icon" v-bind:src="iconFile(icon.ChoiceValue)" v-bind:alt="icon.ChoiceName"
                         uk-svg>
                    <div class="">{{ icon.ChoiceName }}</div>
                  </div>
                </template>
                <button v-if="UI.showRestartPortal" v-on:click="restartPortal"
                        class="uk-button uk-button-default uk-button-small uk-margin-left">
                  <span class="uk-icon" uk-icon="icon: refresh"></span><span>&nbsp;Restart</span>
                </button>
              </div>
            </div>
          </div>
          <div class="gridbackground" uk-cover></div>
          <!-- Instruction Pane  -->
          <div v-if="UI.showInstructionPane" id="InstructionPane"
               class="uk-position-relative instruction-pane uk-card uk-card-default">
            <div class="uk-card-body">
              <h4 class="uk-h3">Getting Started:</h4>
              <ol>
                <li>
                  Start by choosing a product type from the menu.
                </li>
                <li>
                  Click the <span class="fas fa-images" aria-hidden="true"></span><strong>Image
                  Library</strong> from
                  the
                  Project panel.
                </li>
                <li>From your Image Library dashboard you can upload new images, work with existing
                  images and create
                  folders to organize your projects.
                </li>
              </ol>
            </div>
          </div>
          <!-- End Instruction Pane  -->
          <!-- Image and Product Options Preview  -->
          <div v-if="UI.layoutPreview.showProductPreview" id="ProductPreview"
               class="product-preview uk-position-relative">
            <svg v-bind:view-box.camel="viewBoxCalc" class="product-preview-svg">
              <defs>
                <rect id="boundingBox" x="0" y="0" v-bind:width="UI.svgPreview.width"
                      v-bind:height="UI.svgPreview.height"
                      class="svgBox" v-bind:rx="UI.svgPreview.cornerRadius"/>
                <clipPath id="cornerPreview" clipPathUnits="objectBoundingBox">
                  <use href="boundingBox"/>
                </clipPath>
                <pattern id="fluteH" width="1" height="1" patternUnits="userSpaceOnUse">
                  <path d="M 1 0 L 0 0" fill="none" stroke="gray" stroke-width=".125"/>
                </pattern>
                <pattern id="fluteV" width="1" height="1" patternUnits="userSpaceOnUse">
                  <path d="M 0 1 L 0 0" fill="none" stroke="gray" stroke-width=".125"/>
                </pattern>
              </defs>
              <image v-bind:width="UI.svgPreview.width" v-bind:height="UI.svgPreview.height"
                     v-bind:href="activeImage.base64String" preserveAspectRatio="none"/>
              <!--  Might be able to combine these to rects -->
              <use v-if="showSVGOverlay" href="#boundingBox"/>
              <rect v-if="showFlutes" width="100%" height="100%" v-bind:fill="fluteDirection"/>
              <g v-for="hole in SVGDecoration()">
                <!--  Edge to Center of Hole Line -->
                <text v-bind:x="hole.c2eTX" v-bind:y="hole.c2eTY" class="svgC2Etext"
                      v-bind:font-size="UI.svgPreview.textSize">{{ activeImage.hole_edge_distance }}
                </text>
                <line v-bind:x1="hole.c2eLinex1" v-bind:y1="hole.c2eLiney1" v-bind:x2="hole.c2eLinex2"
                      v-bind:y2="hole.c2eLiney2" class="svgLine" v-bind:id="hole.lineName" stroke-dasharray="2,2"/>
                <!--  Center to Center Line -->
                <text v-bind:x="hole.c2cTX" v-bind:y="hole.c2cTY" class="svgC2Etext"
                      v-bind:font-size="UI.svgPreview.textSize">{{ activeImage.hole_hole_distance }}
                </text>
                <line v-bind:x1="hole.dLinex1" v-bind:y1="hole.dLiney1" v-bind:x2="hole.dLinex2"
                      v-bind:y2="hole.dLiney2"
                      class="svgDLine" v-bind:id="hole.dlineName"/>
                <!--  Hole -->
                <circle v-bind:cx="hole.x" v-bind:cy="hole.y" v-bind:r="hole.r" class="svgHole" v-bind:id="hole.name"/>
              </g>
            </svg>
          </div>
          <!--  End product preview -->

          <!--  Rigid sheet layout preview -->
          <div v-if="sheetPreview" id="ProductSheetPreview" class="layout-preview uk-position-absolute">
            <div v-if="showSheetPager" class="sheet-pager"
                 v-bind:class="{ 'tallbox-pager': activeImage.Rotate === 'yes' }">
              <div class="uk-button-group">
                <button v-on:click="showPreviewSheet(sheetPIndex)" type="button"
                        class="uk-button uk-button-primary uk-button-small uk-button-basic page-link">
                  <span uk-icon="chevron-left"></span>&nbsp; Prev
                </button>
                <button type="button" class="uk-button uk-button-primary uk-button-small uk-button-basic page-link">
                  {{ displayPreviewSheet }} of {{ UI.acmLayout.previewSheetCount }}
                </button>
                <button v-on:click="showPreviewSheet(sheetNIndex)" type="button"
                        class="uk-button uk-button-primary uk-button-small uk-button-basic page-link">
                  Next &nbsp;<span uk-icon="chevron-right"></span></button>
              </div>
            </div>
            <div v-if="sheetPreview" class="product-sheet-preview uk-position-absolute"
                 v-bind:style="UI.svgPreview.sheetSize">
              <svg v-bind:view-box.camel="viewSheetCalc" class="sheet-preview-svg">
                <image v-for="(img, index) in thisPanelImageCount" v-bind:x="getPanelImage(index, 'xPosition')"
                       v-bind:y="getPanelImage(index, 'yPosition')" v-bind:width="UI.svgPreview.width"
                       v-bind:height="UI.svgPreview.height" v-bind:href="getPanelImage(index, 'base64')"
                       preserveAspectRatio="none"/>
                <rect v-if="showFlutes" width="100%" height="100%" v-bind:fill="fluteDirection"/>
              </svg>
            </div>
          </div>
          <!-- End Rigid Layout Preview -->
          <div class="error-indicators uk-position-absolute uk-position-bottom">
            <div v-if="activeImage.errorList.length > 0"
                 class="error-button uk-position-relative uk-float-left uk-margin-small-right">
              <div class="uk-icon-button uk-animation-shake uk-alert-danger" type="button" uk-icon="warning"></div>
              <div uk-drop="mode: click">
                <div class="uk-alert uk-alert-danger uk-padding-small uk-margin-remove">
                  <p v-for="error in activeImage.errorList" v-html="error"></p>
                </div>
              </div>
            </div>
            <div v-if="activeImage.warningList.length > 0" class="error-button uk-position-relative uk-float-left">
              <div class="uk-icon-button uk-animation-shake uk-alert-warning" type="button" uk-icon="info"></div>
              <div uk-drop="mode: click">
                <div class="uk-alert uk-alert-warning uk-padding-small uk-margin-remove">
                  <p v-for="error in activeImage.warningList" v-html="error"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Right side product previews -->
      <!-- End Grid for library and preview -->
    </div>
  </section>
  <!--  End library and preview section -->
  <!-- Products and Options Row-->
  <section id="ProductOptions" class="uk-section uk-section-xsmall uk-padding-remove-top">
    <div class="uk-container uk-container-expand">
      <div class="uk-grid-row-small" uk-grid>
        <!--  Height and Width -->
        <div v-bind:class="{ lockedOptionHighlight: showLockedOption('InchHeight') }" class="uk-width-1-4"
             v-bind:hidden="!showHeightWidth">
          <label for="LayoutHeight" class="">
            Height (in): <input class="uk-input" type="number" id="LayoutHeight"
                                v-model.number="activeImage.InchHeight"/>
          </label>
        </div>
        <div v-bind:class="{ lockedOptionHighlight: showLockedOption('InchWidth') }" class="uk-width-1-4"
             v-bind:hidden="!showHeightWidth">
          <label v-bind:class="{ showControl: UI.libraryActive }" for="LayoutWidth" class="">
            Width (in): <input class="uk-input" type="number" id="LayoutWidth" v-model.number="activeImage.InchWidth"/>
          </label>
        </div>
        <!-- Template for product_type Options-->
        <template v-for="option in productOptionChoices" :key="option.OptionKey">
          <!--  Select Options -->
          <div v-if="!option.IsCheckbox && !option.IsTextbox" v-bind:id="'col_' + option.OptionKey"
               v-bind:class="{ 'uk-background-primary uk-light': showApplyAll(option.OptionKey), 'uk-background-muted': showLockedOption(option.OptionKey) }"
               class="uk-width-1-3" v-bind:hidden="!checkOptionAvailable(option.OptionKey)">
            <label v-bind:for="option.OptionKey">
              {{ option.OptionName }}
              <span v-if="hasHelpText(option.OptionKey)"
                    v-bind:title="getHelpText(option.OptionKey)" uk-icon="question"></span>
            </label>
            <select v-model="activeImage[option.OptionKey]" v-on:change="choiceChange" v-bind:id="option.OptionKey"
                    v-bind:name="option.OptionKey" class="uk-select">
              <option value="Select">
                Select
              </option>
              <option v-for="choice in initChoiceOptions(option.OptionKey)" :value="choice.ChoiceValue"
                      v-bind:disabled="!choice.ChoiceAvailable"
                      v-bind:class="{ choiceDisabled: !choice.ChoiceAvailable }"
                      v-text:="getChoiceText(choice.ChoiceName, choice.ChoiceAvailable)">
              </option>
            </select>
          </div>
          <!--  Checkbox Options -->
          <div v-if="option.IsCheckbox" v-bind:id="'col_' + option.OptionKey"
               v-bind:class="{ 'uk-background-primary uk-light': showApplyAll(option.OptionKey), 'uk-background-muted': showLockedOption(option.OptionKey) }"
               class="uk-width-1-4 checkbox-item" v-bind:hidden="!checkOptionAvailable(option.OptionKey)">
            <label v-bind:for="option.OptionKey">
              {{ option.OptionName }}
              <span v-if="hasHelpText(option.OptionKey)"
                    v-bind:title="getHelpText(option.OptionKey)" uk-icon="question"></span>
            </label>
            <input type="checkbox" v-model="activeImage[option.OptionKey]" v-on:change="choiceChange"
                   v-bind:id="option.OptionKey" v-bind:name="option.OptionKey" class="uk-checkbox">
          </div>
          <!--  Textbox Options -->
          <div v-if="option.IsTextbox" v-bind:id="'col_' + option.OptionKey"
               v-bind:class="{ 'uk-background-primary uk-light': showApplyAll(option.OptionKey), 'uk-background-muted': showLockedOption(option.OptionKey) }"
               class="uk-width-1-4" v-bind:hidden="!checkOptionAvailable(option.OptionKey)">
            <label v-bind:for="option.OptionKey">
              {{ option.OptionName }}
              <span v-if="hasHelpText(option.OptionKey)"
                    v-bind:title="getHelpText(option.OptionKey)" uk-icon="question"></span>
            </label>
            <input type="text" v-model="activeImage[option.OptionKey]" v-on:change="choiceChange"
                   v-bind:id="option.OptionKey" v-bind:name="option.OptionKey" class="uk-input">
          </div>
        </template>
      </div><!-- All the options row-->
      <section id="ApplyButtons" class="uk-section uk-section-xsmall">
        <div uk-grid>
          <!-- Locked Options-->
          <div class="uk-width-1-3" v-bind:hidden="!showLockedOption('Message')">
            <button type="button" id="btnApplyAll" class="btnApplyAll uk-button uk-button-muted">These
              Options Applied
              To
              All Panels!
            </button>
          </div>
          <!-- Apply to All-->
          <div class="uk-width-1-3 hiddenControls" v-bind:hidden="!showApplyAll('Button')">
            <button type="button" id="btnApplyAll" class="btnApplyAll uk-button uk-button-primary"
                    v-on:click="applyOptionsToAll">Apply these options to all panels
            </button>
          </div>
        </div>
      </section>
      <section id="TotalsAndCart" class="uk-section uk-section-xsmall uk-padding-remove-top">
        <!-- Project Details-->
        <div uk-grid>
          <div class="bottom-info-button-container uk-flex">
            <div class="left-info-container">
              <div class="totals-container uk-flex">
                <div class="uk-margin-right">
                  <strong>{{ cartTotalText }}<span id="SqFtTotal">{{ project.sqFtTotal }}</span></strong>
                </div>
                <div class="uk-margin-right">
                  <strong>Total Items: <span id="PartQty">{{ totalItems }}</span></strong>
                </div>
                <div v-if="showPrice" class="uk-margin-right">
                  <strong>Total Price: <span id="GrandTotal">{{ project.grandTotal }}</span></strong>
                </div>
                <div v-else class="uk-margin-right"><strong>Price: N/A</strong></div>
              </div>
              <!-- min charge -->
              <div v-if="showMinCharge()" class="min-charge uk-float-right uk-margin-right">
                <p class="uk-text-danger uk-margin-remove">( Minimum Charge: $150.00 ) </p>
              </div>
            </div>
            <div class="buttons-container uk-flex">
              <div class="addToCart uk-margin-right">
                <button v-bind:disabled="project.imageList.length == 0" type="button"
                        class="uk-button uk-button-primary project-button" id="AddToCart"
                        v-on:click="addToCart">{{ cart.cartButtonText }}
                </button>
              </div>
              <div v-if="isFranchiseEnabled('specSheets')" class="uk-flex uk-flex-center uk-margin-remove">
                <a v-bind:href="artSpecPath"
                   class="uk-button uk-button-primary project-button uk-align-middle"
                   target="_blank">Questions? Art Specs &#x2197;</a>
              </div>
            </div>
          </div>

          <!--  Modals for cart. FRN Would be nice to combine by fixing cart logic -->
          <div id="AddCartStop" uk-modal="bg-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-width-1-2@m uk-margin-auto-vertical">
              <div class="uk-modal-header">
                <h4 class="uk-modal-title">You must fix these errors to continue.</h4>
              </div>
              <div v-if="this.cart.errors">
                <p v-html="this.cart.errors"></p>
              </div>
              <div class="uk-margin" uk-grid>
                <div>
                  <button v-if="cart.stopCartSubmit" class="uk-button uk-button-default uk-modal-close">Return to Fix
                    Errors
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="AddCartWarning" uk-modal="bg-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-width-1-2@l uk-width-1-3@xl uk-margin-auto-vertical">
              <div class="uk-modal-header">
                <h4 class="uk-modal-title">Before submitting to the cart, be aware of these issues.</h4>
              </div>
              <div v-if="this.cart.errors">
                <p v-html="this.cart.errors"></p>
              </div>
              <div class="uk-margin uk-flex uk-child-width-1-2" uk-grid>
                <div class="">
                  <button v-if="cart.stopCartSubmit"
                          class="uk-button uk-button-primary project-button uk-width-stretch uk-modal-close">Return to
                    Fix
                    Warnings
                  </button>
                </div>
                <div>
                  <button v-if="cart.showCartAnyway" class="uk-button uk-button-primary project-button uk-width-stretch"
                          v-on:click.prevent="addToCartAnyway">Add to Cart Anyway
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="AddCartSuccess" uk-modal="bg-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical"
                 :class="corexFound ? 'uk-width-2-3@l uk-width-1-2@xl' : 'uk-width-1-2@l uk-width-1-3@xl'">
              <p class="uk-h4">{{ cart.cartText }}</p>
              <div class="uk-margin uk-flex" uk-grid :class="corexFound ? 'uk-child-width-1-3' : 'uk-child-width-1-2'">
                <div>
                  <button
                      class="uk-button uk-button-primary project-button uk-width-stretch uk-modal-close uk-margin-right"><span
                      uk-icon="icon: refresh"> </span> Continue Creating
                  </button>
                </div>
                <div v-if="corexFound === true">
                  <button class="uk-button uk-button-primary project-button uk-width-stretch uk-modal-close"
                          v-on:click.prevent="openMisc"><span uk-icon="icon: plus"> </span> Add Step Stakes
                  </button>
                </div>
                <div>
                  <button class="uk-button uk-button-primary project-button uk-width-stretch uk-modal-close"
                          v-on:click.prevent="goToCart"><span uk-icon="icon: cart"> </span> Go To Cart
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="AddCartFail" uk-modal="bg-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-width-1-2@m uk-margin-auto-vertical">
              <p class="uk-h4">{{ cart.cartText }}</p>
              <div class="uk-margin" uk-grid>
                <div>
                  <button class="uk-button uk-button-default uk-modal-close">Continue Creating
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="DeleteConfirmationModal" uk-modal="bg-close: false">
            <div class="uk-modal-dialog uk-modal-body uk-width-1-2@m uk-margin-auto-vertical">
              <div class="uk-modal-header">
                <h4 class="uk-modal-title">Remove Image</h4>
              </div>
              <p>Remove Image: {{ UI.deleteImageName }}</p>
              <p class="uk-alert uk-alert-warning">Are you sure you wish to remove this image from your
                project?</p>
              <div class="uk-margin" uk-grid>
                <div>
                  <button name="deleteImage" class="uk-button uk-button-danger uk-margin-right uk-modal-close"
                          v-on:click="confirmDeleteFromProject()">Delete
                  </button>
                </div>
                <div>
                  <button type="button" name="Cancel" class="uk-modal-close uk-button uk-button-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>

  <div id="ManageFilesModal" class="uk-modal-container" uk-modal="bg-close: false; stack: true">
    <div id="ManageFilesApp" class="uk-modal-dialog" uk-overflow-auto>
      <button class="uk-modal-close-default uk-close-large" type="button" uk-close></button>
      <div class="uk-modal-header">
        <h3 class="uk-modal-title">Manage Files</h3>
      </div>
      <div class="uk-modal-body">
        <manage-files-app is="vue:manage-files-app" v-bind:caller="TheName"></manage-files-app>
      </div>
      <div class="uk-modal-footer">
        <p>Select image to add to project.</p>
      </div>
    </div>
  </div>
</template>