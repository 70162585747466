﻿<script>
import {router} from '../../helpers/router';
import {utilsMixin} from '../../helpers/utils';
import {lv} from '../../helpers/lowenValidation';
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';
import {useWizardStore} from '../../stores/wizard.store.js';
import UIkit from 'uikit';
import Icons from "uikit/dist/js/uikit-icons";

export default {
  data() {
    return {
      username: "",
      password: "",
      loginMessage: "",
      showError: false,
      baseUrl: process.env.APIURL + "/users",
      franchise: process.env.Franchise
    };
  },
  mounted() {
    UIkit.use(Icons);
    // log("query string username is: ", this.$route.query.username);
    if (this.$route.query.username !== undefined) {
      this.username = this.$route.query.username;
      this.password = this.$route.query.foo;
      this.webLogin();
    }
  },
  mixins: [utilsMixin, lv],
  computed: {
    loginRedirect() {
      let returnValue = "portal";
      let wizardStore = useWizardStore();
      if (this.isFranchiseEnabled('tileMenu')) {
        returnValue = "tileMenu";
      }
      if (this.isFranchiseEnabled('library')) {
        returnValue = "patternLibrary";
      }
      let wiz = wizardStore.currentProduct_type;
      // the material guide wizard has a value. Always goes to the portal for now.
      if (this.isNotEmpty(wiz)) {
        returnValue = "portal";
      }
      return returnValue;
    },
  },
  methods: {
    async appLogin(call) {
      let returnValue = "";
      const url = this.baseUrl + "/authenticate";
      call.Franchise = this.franchise;
      // #DecorHack
      if (this.franchise === 'Decor') {
        // call.Franchise = 'Wholesale'
        call.franchise = 'NGS'
      }
      // #WebPrintHack
      if (this.franchise === 'WebPrint') {
        // call.Franchise = 'Wholesale'
        call.franchise = 'Wholesale'
      }
      // console.log("Franchise is: ", this.franchise)
      // if (this.franchise === 'EPD') {
      //   call.Franchise = 'Wholesale'
      // }
      const response = await fetch(url, {
        method: 'POST',
        // With Access-Control-Allow-Origin set in web.config, this will break CORS.
        // credentials: "include",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(call)
      }).then(response => {
        // console.log(response);
        if (response.ok) {
          return response;
        } else {
          throw new Error("Something went wrong");
        }
      }).catch(error => {
        // log("error", error);
        this.loginMessage = "A server error may be preventing login. Refresh the page and try again.";
        this.showError = true;
        return error;
      });

      if (response == 'Error: Something went wrong') {
        // console.log("Made it this far");
        returnValue = response;
        return returnValue;
      }
      returnValue = await response.json();
      return returnValue;
    },
    async webLogin() {
      // log("webLogin");
      let ready = false;
      const authStore = useAuthStore();
      if (this.username !== "" && this.password !== "") {
        ready = true;
      } else {
        this.loginMessage = "User and Password are required.";
        this.showError = true;
      }
      if (ready) {
        const call = {
          "username": this.username,
          "password": this.password,
          "Franchise": this.franchise,
        };
        const response = await this.appLogin(call);
        // console.log("Made it out of first login call...");
        // log("weblogin response ", response);
        if (response.Status === "ok") {
          let rData = response.Data;
          const JwtToken = rData.JwtToken;
          // log("weblogin JwtToken", JwtToken);
          authStore.setJwtToken(JwtToken);
          delete rData.JwtToken;
          const user = rData;
          authStore.setUser(user);

          // Force Password Change Check
          if (rData.ForcePass === true) {
            // don't toggle, hide and show is better.
            UIkit.modal("#LoginModal").hide();
            UIkit.modal("#ChangePassModal").show();
          }

          this.showError = false;
          this.username = "";
          this.password = "";
          UIkit.modal("#LoginModal").hide();
          router.push({ name: this.loginRedirect });
        }

        if (response.Status === "exception") {
          log("exception response", response);
          switch (response.Message) {
            case "InvalidContext":
              this.loginMessage = "System Context Error. If you are unable to login in a few minutes, please contact Lowen for help.";
              break;
            case "InvalidBullseye":
              this.loginMessage = "System Error. Please wait a few minutes, before trying again.";
              break;
            case "InvalidUser":
              this.loginMessage = "Invalid Username or Password";
              break;
            case "InvalidLogin":
              this.loginMessage = "Invalid login attempt";
              break;
            case "DuplicateUser":
              this.loginMessage = "You appear to be logged in. If you are not, please contact Lowen for help.";
              break;
            default:
              this.loginMessage = response.Message;
              break;
          }
          this.showError = true;
        }
      }
    },
    clearFields() {
      // log("Login clearFields");
      this.showError = false;
      this.username = "";
      this.password = "";
    },
  },
};
</script>

<template id="LoginTemplate">
  <div class="uk-modal-dialog uk-modal-body uk-width-1-2@s uk-width-1-3@l uk-width-1-4@xl uk-margin-auto-vertical">
    <h2 class="uk-modal-title">Login</h2>
    <hr>
    <div v-if="showError" class="uk-alert uk-alert-warning">
      {{ loginMessage }}
    </div>
    <form autocomplete="on" class="uk-align-center uk-child-width-1-1">
      <div class="uk-margin">
        <input id="txtUsername" class="uk-form-large uk-form-blank uk-width-stretch" type="text" autocomplete="on"
               v-model="username" placeholder="Username">
      </div>
      <div class="uk-margin">
        <input id="txtPassword" class="uk-form-large uk-form-blank uk-width-stretch" type="password" autocomplete="on"
               v-model="password"
               placeholder="Password">
      </div>
      <div class="uk-margin uk-flex uk-flex-center uk-child-width-1-2">
        <button class="uk-button uk-button-primary project-button uk-margin-right " v-on:click.prevent="webLogin">
          Login
        </button>
        <button class="uk-button uk-button-primary project-button uk-modal-close " v-on:click="clearFields">Cancel
        </button>
      </div>
      <div v-if="franchise === 'Wholesale'" class="uk-text-small">
        <router-link :to="{name: 'apply'}" class="uk-float-left uk-button uk-button-link uk-modal-close">Create an
          Account
        </router-link>
      </div>
    </form>
    <div class="uk-text-small">
      <a class="uk-float-right uk-button uk-button-link" href="#" uk-toggle="target: #ResetModal">
        Forgot Password?
      </a>
    </div>
  </div>
</template>