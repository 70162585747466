<script>
import {processExpression} from '@vue/compiler-core';
import {router} from '../../helpers/router';
import {useWizardStore} from '../../stores/wizard.store.js';
import {useAuthStore} from '../../stores/auth.store.js';
import {authHeader} from '../../helpers/authheader';
import Login from "./Login.vue";
import UIkit from "uikit";
import Loading from "./LoadingAnimation.vue";
import OrderPreview from "./OrderPreview.vue";

export default {
  data() {
    return {
      baseUrl: process.env.APIURL,
      franchise: process.env.Franchise,
      categories: [],
      options: [],
      dropdownOptions: [],
      currentSubstrate: "",
      currentProductType: "",
      filters: [],
      loaded: false
    }
  },
  mounted() {
    this.getTiles();
    // log("Franchise: ", this.franchise);
    // this.getAllMaterials();
    // this.fetchAllMaterials();
  },
  components: {
    "Loading": Loading,
  },
  methods: {
    setCurrentSubstrate(selection) {
      this.currentSubstrate = selection;
      this.currentProductType = selection.productType;
      let wizardStore = useWizardStore();
      wizardStore.setSubstrateName(selection.substrateName);
      wizardStore.setSubstrateValue(selection.substrateValue);
      wizardStore.setProductType(selection.productType);
      wizardStore.setSubstrateTile(selection.category);
      wizardStore.setSubstrateOptionValue(selection.optionValue);
      wizardStore.setwizardActive(true);
      // log("Current SubstrateName: ", this.currentSubstrate);
      // log("Current SubstrateValue: ", wizardStore.substrateValue);
      // log("Current Product Type: ", this.currentProductType);
      // log("Current Tile: ", selection.category);
      // log("Selection: ", selection);
    },
    setCurrentSubstrateDropDown(selection) {
      log("Current selection: ", selection);
      this.currentSubstrate = selection;
      this.currentProductType = selection.productType;
      let wizardStore = useWizardStore();
      // console.log("using wizardStore");
      wizardStore.setSubstrateName(selection.substrateName);
      wizardStore.setSubstrateValue(selection.substrateValue);
      wizardStore.setProductType(selection.productType);
      wizardStore.setSubstrateTile("");
      wizardStore.setSubstrateOptionValue(selection.optionValue);
      wizardStore.setwizardActive(true);
      // console.log("Groot says ", wizardStore.currentSubstrateValue);
      // console.log("Groot says option", selection.optionValue);
    },
    goToUpload() {
      console.log("goToUpload");
      let authStore = useAuthStore();
      if (authStore.user) {
        router.push({ name: 'wizard-portal' });
      } else {
        UIkit.modal("#LoginModal").show();
      }
    },
    goToPatternCreation() {
      router.push({ name: 'designer' });
    },
    goToPatternLibrary() {
      router.push({ name: 'patternLibrary' });
    },
    navigateByFilter(filter, option) {
      console.log("navigateByFilter", filter);
      console.log("navigateByFilter", option);
      this.setCurrentSubstrateDropDown(option);

      if (filter === 'upload') {
        this.goToUpload();
      } else if (filter === 'designer') {
        this.goToPatternCreation();
      } else if (filter === 'library') {
        this.goToPatternLibrary();
      } else {
        router.push({ name: 'home' });
      }
    },
    async getTiles() {
      const url = this.baseUrl + "/TileMenu/GetTiles";
      const { user } = useAuthStore();
      // log("user", user);
      const call = {
        userId: 0,
        franchise: this.franchise
      };
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let callResponse = await responseData.json();
      this.loaded = true;
      // log("Response: ", callResponse);
      let categories = callResponse[0];
      let substrates = callResponse[1];
      let substratesShortList = callResponse[2];
      this.categories = categories;
      this.options = substrates;
      this.dropdownOptions = substratesShortList;
      // this.initSubstrates(substrates);
    },
    // DEPRECATED no longer using this
    async getCategories() {
      const url = this.baseUrl + "/TileMenu/GetCategories";
      const { user } = useAuthStore();
      // log("user", user);
      const call = {
        userId: 0,
        ItemNo: this.franchise
      };
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let callResponse = await responseData.json();
      this.loaded = true;
      // log("Response: ", callResponse);
      // callResponse = JSON.parse(callResponse);
      callResponse.forEach(element => {
        // log("Category Element: ", element);
        this.categories.push(element);
      });
    },
    // no longer using this
    async getAllMaterials() {
      // this.fetchAdhesives();
      // this.fetchRigid();
      let types = ["adhesive", "rigid", "Wallcoverings", "Window Decoration", "Magnetic", "Rolled"];
      types.forEach(type => {
        this.fetchAllMaterials(type);
      });
    },
    // no longer using this
    async fetchAllMaterials(type) {
      const url = this.baseUrl + "/TileMenu/GetAllMaterials";
      const { user } = useAuthStore();
      // log("user", user);
      let userId = 0;
      if (!user) {
        userId = 0;
      }

      const call = {
        userId: userId,
        product_type: type,
        ItemNo: ""
      };
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let callResponse = await responseData.json();
      callResponse = JSON.parse(callResponse);
      //   log("Response: ", this.callResponse);
      callResponse.forEach(element => {
        // log("Element: ", element);
        this.options.push(element);
      });

      log("Options: ", this.options);
      // log("activeImage", this.activeImage);
      // log("UI", this.UI);
      // return this.callResponse;
    },
    reset() {
      this.currentSubstrate = "";
      this.currentProductType = "";
      let wizardStore = useWizardStore();
      wizardStore.setSubstrateName("");
      wizardStore.setSubstrateValue("");
      wizardStore.setProductType("");
      wizardStore.setwizardActive(false);
    },
    formatCategory(category) {
      category = category.replace(/ /g, '-');
      category = category.replace(/\//g, '-');
      category = category.replace(/&/g, 'and');
      return category;
    },
    shortenedName(substrateName) {
      let shortened = substrateName;
      if (substrateName.startsWith("3M")) {
        shortened = substrateName.substring(2, substrateName.length);
      }
      if (substrateName.startsWith("DreamScape")) {
        shortened = substrateName.substring(10, substrateName.length);
      }
      if (substrateName.startsWith("Lintec")) {
        shortened = substrateName.substring(6, substrateName.length);
      }
      return shortened;
    },
    genFilterString(list) {
      let filterString = "";
      if (list) {
        list.forEach(element => {
          if (element !== 'designer') {
            filterString += '.' + element + ', ';
          } else {
            if (this.franchise === "NGS") {
              filterString += '.' + element + ', ';
            }
          }
        });
        // remove comma and space after last element
        return filterString.slice(0, -2);
      }
    },
    getTileImage(category) {
      // log("Flag: ", category);
      let filePrefix = "/public/images/tiles/";
      switch (category) {
        case "Printed Graphics":
          return filePrefix += "PrintedGraphics_Button.jpg";

        case "Wallpaper":
          return filePrefix += "Wallpaper_Button.jpg";

        case "Architectural Finishes":
          return filePrefix += "ArchitecturalFinishes_Button.jpg";

        case "Rough Walls":
          return filePrefix += "RoughWalls_Button.jpg";

        case "Glass Walls & Windows":
          return filePrefix += "GlassWallsWindows_Button.jpg";

        case "Floors":
          return filePrefix += "Floors_Button.jpg";

        case "Car/Fleet/Rail Wraps":
          return filePrefix += "Car_Fleet_Rail Wraps_Button.jpg";

        case "Signage":
          return filePrefix += "Signage_Button.jpg";

        default:
          return "Image not found";
      }
    },

    getBrandImage(substrate) {
      let substring = substrate.substring(0, 2);
      let filePrefix = "/public/images/";
      // log("substrate: ", substrate);
      if (substrate.startsWith('3M')) {
        // log("Found 3M");
        return filePrefix += "3MMenuLogo.svg";
      }
      if (substrate.startsWith('DreamScape')) {
        // log("Found DreamScape");
        return filePrefix += "DS_PPN_Badge.png";
      }
      if (substrate.startsWith("Lintec")) {
        // log("Found Lintec");
        return filePrefix += "LintecLogo.png";
      }
      if (substrate.startsWith("ACM N")) {
        // log("Found ACM Non-reflective");
        return filePrefix += "ACM_no_border.png";
      }
      if (substrate.startsWith("ACM R")) {
        // log("Found ACM Reflective");
        return filePrefix += "ACM_Reflective_no_border.png";
      }
      if (substrate == "Corex") {
        // log("Found Corex");
        return filePrefix += "Corex_no_border.png";
      }
      if (substrate.startsWith("GF")) {
        // log("Found General Formulation");
        return filePrefix += "GF_logo.png";
      }
      if (substrate.startsWith("Avery")) {
        // log("Found General Formulation");
        return filePrefix += "Avery_logo.png";
      }
      if (substrate.startsWith("Clear Focus")) {
        // log("Found General Formulation");
        return filePrefix += "Clear_Focus_logo.png";
      }
    },
    getTileDescription(substrateName) {
      if (substrateName === "DreamScape Suede") {
        return "For murals and wallcoverings featuring embossed vinyl face with laminated fabric backing for indoor use.";
      }
      if (substrateName === "Lintec E-2200ZC Scratch Resist") {
        return "Transparent polyester film suitable for window display applications with scratch-resistant surface for indoor use.";
      }
      if (substrateName === "Lintec Rolled E-2100ZC") {
        return "Transparent polyester film suitable for window display applications with scratch-resistant surface for indoor use.  By the roll.";
      }
      if (substrateName === "3M Non-Vinyl Material") {
        return "Versatile film for windows, walls, floors, signs and more suitable for indoor and outdoor use. ";
      }
      if (substrateName === "3M 40C-10R Gloss White") {
        return "Gloss film for floors and flat surfaces suitable for indoor and outdoor use. ";
      }
      if (substrateName === "3M 40C-20R Matte White") {
        return "Matte film for floors and flat surfaces suitable for indoor and outdoor use. ";
      }
      if (substrateName === "3M LX480mC") {
        return "A versatile wrap for everything from cars and boats to trailers and textured buildings. The non-PVC construction is conformable, durable and installs in a wider temperature range.  Good for rough or flat surfaces, compound and simple curves.  For indoor and outdoor use.";
      }
      if (substrateName === "3M IJ180Cv3") {
        return "A versatile, conformable 2 mil film with slideable, repositionable, and medium/higher tack. It features Comply™ v3 Adhesive for fast, easy, and bubble-free installation.  Ideal for vehicle wraps, fleet graphics, and smooth or lightly textured wall graphics.";
      }
      if (substrateName === "3M IJ180mC-10 White") {
        return "Versatile film Ideal for vehicle wraps, fleet graphics, and smooth or lightly textured wall graphics.";
      }
      if (substrateName === "3M IJ35C") {
        return "Permanent adhesive, calendared film suitable for indoor and outdoor promotional graphics.";
      }
      if (substrateName === "3M IJ680CR") {
        return "Permanent flexible retroreflective film for commercial vehicle, rail and bus graphics, sign graphics and striping.";
      }
      if (substrateName === "3M IJ8624 for textured walls") {
        return "Conforms with heat to moderately textured surfaces including concrete blocks, bricks, stucco and tile like surfaces.";
      }
      if (substrateName === "3M IJ8150 Clear View") {
        return "Optically clear vinyl film for use on building and automotive glass, acrylic and polycarbonate surfaces.";
      }
      if (substrateName === "3M 8170-P50/50 Window Film") {
        return "High gloss vinyl film for architectural and vehicle window graphics with a 50% perforation pattern for full image visibility outside while allowing good visibility inside.";
      }
      if (substrateName === "Avery MPI 1105 EZ (Prespaced)") {
        return "Avery Dennison MPI 1105 SuperCast vinyl is a removable, highly conformable film for use in fleet marking and vehicle wraps as well as corporate identity applications. EZ is designed for pre-spaced lettering and graphics";
      }
      if (substrateName === "Avery MPI 1105 EZRS (Wraps)") {
        return "Avery Dennison MPI 1105 SuperCast vinyl is a removable, highly conformable film for use in fleet marking and vehicle wraps as well as corporate identity applications.  EZRS is designed for wraps.";
      }
      if (substrateName === "Avery V4000 EZRS") {
        return "Exceptional value for architectural, transportation, and general signage applications requiring conformability and extended durability.";
      }
      if (substrateName === "GF 235 MotoMark LSE") {
        return "4.0 mil “GRIP” is specially formulated premium flexible matte white PVC film. This film has a unique “High Bond” solvent acrylic pressure sensitive adhesive with a 90# layflat liner. The adhesive is formulated for the powersport graphic and decal markets.";
      }
      if (substrateName === "GF 201 white (Permanent)") {
        return "Value priced, Permanent adhesive, calendared film suitable for indoor and outdoor promotional graphics";
      }
      if (substrateName === "GF 210 Traffic Graphic") {
        return "a 3.4 mil polymeric matte white flexible calendered vinyl film with a removable high performance pressure sensitive acrylic adhesive on a 90# layflat liner";
      }
      if (substrateName === "Clear Focus Window Film 50/50") {
        return "Economy-grade composite PVC film with perforation pattern for short-term use on architectural windows. Commonly used for retail advertising and brand identity.";
      }
      if (substrateName === "ACM Non-Reflective") {
        return "Aluminum composite material (Non-reflective) is lightweight and has excellent durability.";
      }
      if (substrateName === "ACM Reflective") {
        return "Aluminum composite material (Reflective) is lightweight and has excellent durability.";
      }
      if (substrateName === "Corex") {
        return "Corrugated / fluted plastic signs are lightweight, durable and recyclable.";
      }

      return "Unable to find a description for this material.";
    }
  },

  computed: {
    showPatternButton() {
      if (this.franchise === "NGS") {
        if (this.currentSubstrate.substrateValue === "TS-3M40C-10R" || this.currentSubstrate.substrateValue === "TS-3M40C-20R") {
          return true;
        }
      }
      return false;
    }
  }
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;

}
</style>

<!-- This is all testing to verify functionalty of uk-filter  -->

<template>
  <div id="TileMenuContainer" class="tile-container uk-margin-bottom">
    <h1 id="TileMenuHeader" class="uk-text-center uk-text-primary uk-margin-top">Material Selection Guide</h1>

    <div id="TileMenuTileContainer" class="category-container uk-container uk-padding-remove@m"
         uk-filter="target: .js-filter; selector: .uk-filter-control; animation: delayed-fade">
      <div
          class="tile-menu-nav uk-active sub-menu uk-container uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-text-middle"
          uk-filter-control="filter: .cat">
        <div class="">
          <button class="uk-button uk-button-primary" @click="reset">
            Start Over
          </button>
        </div>

        <div id="TileMenuShortcutContainer" class="uk-margin-left uk-flex uk-flex-row uk-flex-middle uk-text-primary">
          <div id="TileMenuShortcutDropdownContainer" class="dropdown-container">
            <button id="KnowWhatYouNeedButton" class="uk-button uk-button-primary" type="button">Know What You Need?
            </button>
            <div id="KnowWhatYouNeedDopdownOptionsContainer" class="dropdown-content"
                 uk-dropdown="mode: click; pos: bottom-right; animation: slide-top; animate-out: true; duration: 300"
                 uk-toggle>
              <ul id="DropDownOptionsList" class="uk-nav uk-dropdown-nav">
                <li class="tile-menu-dropdown-option uk-padding-horizontal" v-for="option in this.dropdownOptions"
                    :id="option.substrateName + 'DropDownLi'">
                  <a v-if="!option.substrateUnAvailable" :id="'SetSubstrate-' + option.substrateName" href="#"
                     :uk-filter-control="'filter: ' + genFilterString(option.filters)"
                     @click="setCurrentSubstrateDropDown(option)">
                    {{ option.substrateName }}
                  </a>
                  <p v-else
                     class="unavailable uk-padding-left uk-margin-remove uk-padding-small uk-padding-remove-horizontal"
                     :disabled="option.substrateUnavailable">
                    {{ option.substrateName }} (unavailable)
                  </p>
                  <!--                maybe we don't need this sub-menu?                   -->
                  <div :id="'Subnav-dropdown-' + formatCategory(option.substrateName)" class="uk-padding-remove"
                       v-if="option.filters.length > 0"
                       uk-dropdown="mode: hover; shift: true; pos: left-top; offset: 15; animation: slide-right; animate-out: true; duration: 300">

                    <!-- Future Deploy - Hide Links until ready to deploy -->
                    <ul :id="'Subnav-dropdown-' + formatCategory(option.substrateName) +'-ul'"
                        class="uk-nav uk-dropdown-nav ">
                      <li :id="'Subnav-dropdown-' + formatCategory(option.substrateName) +'-li-header'"
                          class="uk-nav-header uk-text-center uk-text-small uk-background-muted">Available Options
                      </li>
                      <li :id="'Subnav-dropdown-' + formatCategory(option.substrateName) +'-li-filter'"
                          class="tile-menu-dropdown-option uk-text-uppercase uk-padding-small"
                          v-for="filter in option.filters" :uk-filter-control="'filter: .' + filter">
                        <a v-if="filter != 'library'"
                           :id="'Subnav-dropdown-' + formatCategory(option.substrateName) +'-li-' + filter"
                           class="uk-flex-around uk-padding-remove" href="#"
                           @click="navigateByFilter(filter, option)">
                          {{ filter }}</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- <div>
              <button class="uk-button uk-button-primary" @click="setCurrentSubstrateDropDown">Get Started</button>
          </div> -->
        </div>
      </div>
      <div class="tile-button-container uk-padding uk-padding-remove-horizontal">
        <div class="uk-text-middle uk-padding-small">
          <p class="uk-margin-remove">Current Selection:
            <span id="CurrentSubstrateName"
                  class="selection-span uk-text-primary">{{ this.currentSubstrate.substrateName }} </span>
          </p>
        </div>
        <Loading v-if="!this.loaded"/>
        <div class="js-filter uk-text-center uk-width-1-1 uk-margin-remove" uk-grid>
          <!-- generated dynamically by the list of categories from the backend  -->
          <a v-for="item in categories" :uk-filter-control="'filter: .' + formatCategory(item)"
             class="cat uk-width-1-2@s uk-width-1-3@m uk-position-relative uk-padding-small uk-margin-small-top uk-padding-remove-bottom"
             :id="formatCategory(item) + '-Tile-Button'">
            <div class="cat-tile" v-bind:data-src="this.getTileImage(item)" uk-img>
            </div>
            <p :id="formatCategory(item) + '-Title'" class="uk-text-large tile-title uk-margin-remove">
              {{ item }}
            </p>
          </a>
          <!-- generated dynamically by the available substrates -->
          <div v-for="option in options"
               :class="formatCategory(option.category) + ' uk-width-1-2@s uk-width-1-3@m uk-position-relative uk-padding-small uk-margin-small-top uk-padding-remove-bottom'"
               @click="setCurrentSubstrate(option)"
               :id="formatCategory(option.substrateName) + '-Tile-Button'">
            <a :class="{'disabled' : option.substrateUnAvailable}">
              <div class="cat-tile brand-logo-container uk-visible-toggle"
                   :uk-filter-control="'filter: ' + genFilterString(option.filters)" href="#" disabled>
                <div class="brand-logo uk-background-norepeat"
                     v-bind:data-src="this.getBrandImage(option.substrateName)" uk-img></div>
                <!-- <img class="uk-img" src="/public/images/lintec_logo.png" alt="Aspire"/> -->
                <span class="substrate-info uk-hidden-hover">{{ getTileDescription(option.substrateName) }}</span>
              </div>
              <p :id="formatCategory(option.substrateName) + '-Title'"
                 class="uk-text-large subtile-title uk-margin-remove uk-flex uk-flex-column">
                <span>{{ shortenedName(option.substrateName) }}</span>
                <span v-if="option.substrateUnAvailable">( Temporarily Unavailable )</span>
              </p>
            </a>
          </div>
          <!-- available redirect paths will show based on substrate filters assigned on the backend -->

          <!-- Future Deploy - Hide Pattern Library Links until ready to deploy -->
          <a id="Pattern-Library-Tile"
             class="library uk-width-1-2@s uk-width-1-3@m uk-position-relative uk-padding-small uk-margin-small-top uk-padding-remove-bottom"
             @click="goToPatternLibrary">
            <div class="cat-tile uk-position-relative uk-visible-toggle" href="#">
              <span class="uk-hidden-hover tile-nav-icon" uk-icon="icon: album; ratio: 6"></span>
            </div>
            <p id="Pattern-Library-Tile-Title" class="uk-text-large tile-title uk-margin-remove">
              Pattern Library
            </p>
          </a>
          <a id="Pattern-Designer-Tile"
             class="designer uk-width-1-2@s uk-width-1-3@m uk-position-relative uk-padding-small uk-margin-small-top uk-padding-remove-bottom"
             @click="goToPatternCreation">
            <div class="cat-tile uk-position-relative uk-visible-toggle" href="#">
              <span class="uk-hidden-hover tile-nav-icon" uk-icon="icon: file-edit; ratio: 6"></span>
            </div>
            <p id="Pattern-Designer-Tile-Title" class="uk-text-large tile-title uk-margin-remove">
              Pattern Designer
            </p>
          </a>
          <a id="Upload-Tile"
             class="upload uk-width-1-2@s uk-width-1-3@m uk-position-relative uk-padding-small uk-margin-small-top uk-padding-remove-bottom"
             @click="goToUpload">
            <div class="cat-tile uk-position-relative uk-visible-toggle" href="#">
              <span class="uk-hidden-hover tile-nav-icon" uk-icon="icon: upload; ratio: 6"></span>
            </div>
            <p id="Upload-Tile-Title" class="uk-text-large tile-title uk-margin-remove">
              Upload an Image
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>