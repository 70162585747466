<script setup>
import {useAuthStore} from '../stores/auth.store.js';
import {authHeader} from '../helpers/authheader';
import {useWizardStore} from "../stores/wizard.store";
import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import {router} from '../helpers/router';
import Search from './Components/Search.vue';
</script>

<script>
export default {
  data() {
    return {
      pageId: 'designer',
      // Dynamically change the starting pattern of architexures
      artxPatternId: 729, // this is the default background for architexures iframe
      searchText: '',
      textureData: {
        userId: 0,
        LineNumber: 0,
        ItemSeqNo: 0,
        prevItemSeqNo: 0,
        CartID: 0,
        product_type: this.product_type,
        // must set these when the product is got
        ItemDesc: "",
        ItemNo: "Select",
        MaterialText: "",
        Price: 0.00,
        Filename: "",
        TextureId: "",
        TextureName: "",
        DesignNickname: "",
        ArtParams: "",
        TextureParams: "",
        TextureImageB64: "",
        BumpFileB64: "",
        TextureImage: "",
        BumpFile: "",
        InchHeight: 0,
        InchWidth: 0,
        ImageUrl: "",
        Thumbnail: "",
        FullUrl: "",
        Type: "Select",
        OrderNotes: "",
        Quantity: 1,
        isFinalized: false,
        rollSize: "Select",
      },
      textureLibrary: {},
      designMessage: "Not saved",
      isProject: false,
      isOrder: false,
      isSample: false,
      textureChoice: 0,
      productList: [],
      isError: false,
      isLibraryError: false,
      libraryMessage: "You have not saved any designs.",
      nickNameWarning: "Please enter a nickname for your design before saving.",
      showNicknameWarning: true,
      baseUrl: process.env.APIURL,
      franchise: process.env.Franchise,
      overlayActive: false,
      libraryOverlayActive: false,
      unsavedChanges: true,
      orderConfirmed: false,
      showCartMessage: false,
      showFinalizeMessage: false,
      cartMessage: "",
      cartErrorMessage: [],
      isCartError: false,
      product_type: "Rolled",
      configData: {},
      saveDesignQueue: {
        bump: false,
        textureData: false,
        art: false,
        image: false,
      },
      bumpFetched: false,
      textureDataFetched: false,
      artFetched: false,
      imageFetched: false,
      saving: false,
      loading: true,
      finalizing: false,
      messageFetchList: [],
      wizard: useWizardStore(),
    };
  },
  beforeMount() {
    let artxID = localStorage.getItem('artxId');
    // console.log("artxID", artxID);
    if (artxID) {
      this.artxPatternId = artxID;
    }
    localStorage.removeItem('artxId');
  },
  mounted() {
    // log("Textures Designer is mounted");
    this.atCreateListener();
    const getTextures = this.getTextureLibrary;
    UIkit.util.on("#LibraryControls", "beforeshow", function () {
      getTextures();
    });
    this.setConfigData();
    this.$nextTick(() => {
      this.artWindow = document.getElementById("Arch").contentWindow;
    });
    // If This don't work you have to get the iframe like this in each function.
    // let w = document.getElementById("Arch").contentWindow;
    // Calls to/for the Architextures API should be / are preceeded by at
  },
  components: {
    "Search": Search,
  },
  methods: {
    artxMessage(type, data) {
      // _this = this;
      // log("artxMessage: ", type, data);
      let randomID = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
      // if (!this.messageFetchList.includes(randomID)) {
      // console.log("Making it here..", type);
      // _this.messageFetchList.push(randomID);
      this.artWindow.postMessage({type: type, data: data, requestId: randomID}, "*");
      // console.log("RequestID: ", randomID);
      return new Promise(function (resolve) {
        // console.log("Making it inside promise...", type);

        function filterMessages(e) {
          // console.log("filterMessages", e.data);
          if (e.data.hasOwnProperty("artx") && e.data.artx.requestId === randomID) {
            window.removeEventListener("message", filterMessages);
            // log("resolved data: ", e.data.artx.data);
            resolve(e.data.artx.data);

          }
        }

        window.addEventListener("message", filterMessages);
      });
      // }
    },
    prestageFromWizard() {
      if (this.wizard.wizardActive) {
        // log("wizard substrateName: ", this.wizard.substrateName);
        // log("wizard substrateValue:", this.wizard.substrateValue);
        // log("wizard product type: ", this.wizard.productType);
        this.textureData.ItemNo = this.wizard.substrateValue;
        this.textureData.MaterialText = this.wizard.substrateName;
        this.textureData.tile = this.wizard.substrateTile;
        this.loadedFromWizard = true;
        this.overlayActive = !this.overlayActive;
        // this.wizard.wizardActive = false;
      }
    },
    choiceChange() {
      // console.log("choiceChange current activeImage: ");
      // console.log(this.activeImage);
      this.setConfigData();
    },
    checkOptionAvailable(OptionKey, image) {
      //Using the dtOptionCondition determine if the option is valid for the users choices.
      //console.log("checkOptionAvailable: " + OptionKey);
      //console.log("Current configuration data: ");
      //console.log(this.configData);
      const OptionConditions = this.configData.dtOptionCondition;
      const conditions = _.filter(OptionConditions, ['OptionKey', OptionKey]);
      //console.log("checkOptionAvailable: conditions:");
      //console.log(conditions);
      if (conditions.length > 0) {
        //console.log("activeImage:");
        //console.log(this.activeImage);
        for (let k in conditions) {
          let v = conditions[k];
          //console.log("other option key: " + v.OtherOptionKey);
          let currentState = this.activeImage[v.OtherOptionKey];
          if (image) {
            currentState = image[v.OtherOptionKey];
          }
          //console.log("currentState: " + currentState);
          //console.log("Condition: " + v.Condition)
          //console.log("ConditionValue: " + v.ConditionValue);

          // ** NOTE the reverse test for the implied case. **
          switch (v.Condition) {
            case 'EQ':
              if (!(currentState == v.ConditionValue)) {
                return false;
              }
              break;
            case 'NEQ':
              if (currentState == v.ConditionValue) {
                return false;
              }
              break;
            case 'GT':
              if (!(parseFloat(currentState) > parseFloat(v.ConditionValue))) {
                return false;
              }
              break;
            default:
          }
        }
      }
      //console.log("Default true");
      return true;
    },
    setConfigData: _.debounce(async function () {
      // console.log("setConfigData");
      let jsonData = {};
      let data = await this.fetchConfigData();
      jsonData = JSON.parse(data);
      // console.log("setConfigData return: JsonData", jsonData);
      this.configData = jsonData;
      // log("SetConfigData ", this.configData);
      //this.zHardCodeTesting();
      this.manageProductRules(this.product_type);
    }, 100),
    async fetchConfigData() {
      const url = this.baseUrl + "/Portal/GetProductOptions";
      const {user} = useAuthStore();
      let call = _.clone(this.textureData);
      call.userId = user.Id;
      // #DECORHACK
      if (this.franchise === "Decor") {
        // this.Franchise = 'Wholesale'
        this.franchise = 'NGS'
      }
      // #WebPrintHack
      if (this.franchise === 'WebPrint') {
        // call.Franchise = 'Wholesale'
        this.franchise = 'Wholesale'
      }
      call.franchise = this.franchise;
      call.PageId = this.pageId;
      call.product_type = this.product_type;
      call.BumpFileB64 = "";
      call.TextureImageB64 = "";
      call.Thumbnail = "";
      call.ArtParams = "";
      call.TextureParams = "";
      // console.log("fetchConfigData call", call);
      const responseData = await fetch(url, {
        method: 'POST',
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      let configData = await responseData.json();
      return configData;
    },
    manageProductRules(product) {
      // console.log("manageProductRules", product);
      // This is an object literal with a default return type.
      let _this = this;
      //console.log(this.activeImage);
      let defaults = {
        'Rolled': function () {
          if (!_.isEmpty(_this.configData)) {
            let productArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.textureData.ItemNo]);
            if (productArray.length > 0) {
              let product = productArray[0];
              // console.log("Setting Material Text with Product", product);
              if (product) {
                _this.textureData.ItemDesc = product.ChoiceName;
                _this.textureData.MaterialText = product.ChoiceName;
              }
            }
            // When we come from the wizard.
            if (productArray.length > 0 && _this.loadedFromWizard) {
              _this.textureData.ItemDesc = _this.wizard.substrateName;
              _this.textureData.MaterialText = _this.wizard.substrateName;
              // _this.textureData.Type = _this.wizard.substrateLaminateType;
            }

            let typeArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.textureData.Type]);
            if (typeArray.length > 0) {
              let type = typeArray[0];
              // console.log("type", type);
              if (type) {
                // console.log("Setting Material Text with Type", _this.textureData.Type);
                // _this.textureData.Type = type.ChoiceValue;
                _this.textureData.ItemDesc = _this.textureData.ItemDesc + " - " + type.ChoiceName;
                _this.textureData.MaterialText = _this.textureData.MaterialText + " - " + type.ChoiceName;
              }
            }
            let lengthArray = _.filter(_this.configData.dtOptionChoices, ['ChoiceValue', _this.textureData.rollSize]);
            if (lengthArray.length > 0) {
              let length = lengthArray[0];
              // console.log("rollSize", length);
              if (length) {
                // console.log("Setting Roll Length with Length", _this.textureData.rollSize);
                // _this.textureData.Type = type.ChoiceValue;
                _this.textureData.ItemDesc = _this.textureData.ItemDesc + " - " + length.ChoiceName;
                _this.textureData.MaterialText = _this.textureData.MaterialText + " - " + length.ChoiceName;
              }
            }

            _this.updatePricing();
          }
        },
        // no product or blank string.
        '': function () {
          // log("manageProductRules no product");
          let searchParams = new URLSearchParams(window.location.search);
          let cartId = searchParams.get("editid");
          if (cartId) {
            _this.editMyCartItem(cartId);
          }
          if (!cartId) {
            // UIkit.modal("#ProductListModal").show();
          }
        }
      }
      return (defaults[product] || defaults['none'])();
    },
    atCreateListener() {
      let _this = this;
      window.onmessage = function (event) {
        if (event.data.hasOwnProperty("artx")) {
          let response = event.data.artx;
          let rType = response.type;

          // do this when using artxMessage utility
          if (rType != 'getTexture' && rType != 'getBump' && rType != 'getParams' && rType != 'getTextureData') {
            _this.doArtMethods(rType, response);
          }
        }
      };
    },
    async doPreSaveFunction(item) {
      if (item === 'getBump' || item === 'getTexture') {
        this.artWindow.postMessage({
          type: item, data: {
            size: 2000,
            format: "png",
            quality: 1
          }
        }, "*");
      } else {
        this.artWindow.postMessage(item, "*");
      }
    },
    atGetArtParams() {
      // log("getArtParams");
      // let w = document.getElementById("Arch").contentWindow;
      // log("window ", w);
      this.artWindow.postMessage("getParams", "*");
      // log("atGetArtParams - textureData", this.textureData);
      // log("getArtParams");
      // let w = document.getElementById("Arch").contentWindow;
      // log("window ", w);
      // this.artWindow.postMessage("getParams", "*");
    },
    atLoadArtParams() {
      // log("loadArtParams");
      // let w = document.getElementById("Arch").contentWindow;
      // let artData = this.ArtParams;
      // If you want the ArtParams to be JSON, you have to do this.
      // I think they should be JSON for going to the save
      let artData = JSON.parse(JSON.stringify(this.textureData.ArtParams));
      this.artWindow.postMessage({
        type: "loadParams",
        data: artData
      }, "*");
    },
    atSetFranchise() {
      log("SetFranchise");
      // let w = document.getElementById("Arch").contentWindow;
      switch (this.franchise) {
        case "NGS":
          // do this when artxMessage can return a requestID
          // log("Setting franchise...");
          this.artxMessage("setTitle", "Aspire");
          this.artxMessage("setIconSrc", "https://www.aspiretextures.film/public/logos/ASPIREIconBlack.png");
          this.artxMessage("setAccentColor", "#DCF763");
          break;
        case "Decor":
          // do this when artxMessage can return a requestID
          // log("Setting franchise...");
          this.artxMessage("setTitle", "Decor Renaissance");
          this.artxMessage("setIconSrc", "https://lowentradesource.com/public/logos/DecorIcon.png");
          this.artxMessage("setAccentColor", "#4FAAE7");
          break;
        default:
          // log("No Franchise to set");
      }
    },
    doArtMethods(rtype, response) {
      log("doArtMethods", rtype);
      switch (rtype) {
        case "getParams":
          this.handleArtParams(response);
          break;
        case "loadParams":
          this.handleLoadedParams(response);
          break;
        case "getTextureData":
          this.handleTextureData(response);
          break;
        case "getTexture":
          this.handleTextureImage(response);
          break;
        case "downloadRequest":
          this.handleDownloadRequest(response);
          break;
        case "getBump":
          this.handleBumpFile(response);
          break;
        case "editorLoaded":
          this.atSetFranchise();
          break;
        default:
          // log("Nothing to do");
          break;
      }
    },
    saveDesign() {
      if (!this.showNicknameWarning) {
        if (!this.finalizing) {
          this.saving = true;
        }
        // Architextures Settings
        // Horizontal pixels, 2000 is default, 6000 was the highest we got and it crashed, sometimes.
        // Any changes here caused issues.
        let size = 6000;
        // Highest quality is 1. 0.5 is default.
        let quality = 1;
        // I think this is for bump, black and white or white and black.
        let invert = true;
        // PNG or JPG. PNG is the default.
        let format = "png";

        // log("textureData before saving: ", this.textureData);

        this.artxMessage("getTexture", {
          size: size,
          format: format,
          quality: quality,
        })
            .then((textureData) => {
              // console.log("artxMessage response from getTexture: ", textureData);
              this.textureData.TextureImageB64 = textureData;
              this.saveDesignQueue.image = true;
            })
            .then(() => {
              this.artxMessage("getBump", {
                invert: invert,
                size: size,
                format: format,
                quality: quality
              })
                  .then((bumpData) => {
                    // console.log("artxMessage response from getBump: ", bumpData);
                    this.textureData.BumpFileB64 = bumpData;
                    this.saveDesignQueue.bump = true;
                  })
            })
            .then(() => {
              this.artxMessage("getParams")
                  .then((artParams) => {
                    // console.log("artxMessage response from getParams: ", artParams);
                    this.textureData.ArtParams = artParams;
                    this.saveDesignQueue.art = true;
                  });
            })
            .then(() => {
              this.artxMessage("getTextureData")
                  .then((textureData) => {
                    // console.log("artxMessage response from getTextureData: ", textureData);
                    this.textureData.TextureParams = textureData;
                    this.saveDesignQueue.textureData = true;
                  })
            });
      }
    },
    preFinalizeDesign() {
      console.log("preFinalizeDesign");
      this.designMessage = "Must Finalize Design First!";
      this.showFinalizeMessage = true;
    },
    finalizeDesign() {
      this.finalizing = true;
      // Mark design as finalized
      this.textureData.isFinalized = true;
      this.saveDesign();
    },
    getTextureLibrary: _.debounce(async function () {
      this.loading = true;
      // log("getTextureLibrary");
      this.isLibraryError = false;
      const authStore = useAuthStore();
      const user = authStore.user;
      const url = this.baseUrl + "/Textures/GetTextureLibrary";
      this.textureData.userId = user.Id;
      // only need userId, not all of texture data.
      const call = {
        userId: user.Id
      };
      let response = await fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(call)
      });
      const libResult = await response.json();
      // log("libResult", libResult);
      if (libResult.Status === "ok") {
        this.textureLibrary = libResult.Data;
        // log("returned texureLibrary", this.textureLibrary);
        this.loading = false;
      }
      if (libResult.Status === "invalid") {
        this.isLibraryError = true;
        this.libraryMessage = libResult.Message;
      }
      if (libResult.Status === "exception") {
        this.isLibraryError = true;
        this.libraryMessage = libResult.Message;
      }
      this.loading = false;
    }, 500),
    handleArtParams(response) {
      // log("handleArtParams", response.data);
      this.textureData.ArtParams = response.data;
      // this.saveDesignQueue.art = true;
      this.artFetched = true;
      // if (this.textureData.ArtParams){
      //     this.saveDesignQueue.art = true;
      // }
      // let canSave = await this.readyToSave();
      // log("canSave at handleArt", canSave);
      // if(canSave) {this.saveDesignParams()};
    },
    handleLoadedParams(response) {
      // this does not appear to get called. The page is reloaded instead.
    },
    handleTextureData(response) {
      // log("handleTextureData", response.data);
      this.textureData.TextureParams = response.data;
      // this.saveDesignQueue.textureData = true;
      this.textureDataFetched = true;
    },
    handleTextureImage(response) {
      // log("handleTextureImage", response.data);
      this.textureData.TextureImageB64 = response.data;
      // this.saveDesignQueue.image = true;
      this.imageFetched = true;
    },
    handleBumpFile(response) {
      // log("handleBumpFile", response.data);
      this.textureData.BumpFileB64 = response.data;
      // this.saveDesignQueue.bump = true;
      this.bumpFetched = true;
      // log("bumpFile", this.textureData.BumpFileB64);
    },
    handleDownloadRequest(response) {
      // log("handleDownloadRequest", response);
      // do  nothing, we do not allow downloads.
    },
    restoreDesign() {
      // log("restoreDesign");
      // set current to old.
      this.getSavedDesignParams();
      this.unsavedChanges = false;
    },
    loadDesignFromLib(TextureId) {
      // log("loadDesignFromLib", TextureId);
      // need to reset textureData to stop json art and texture params getting passed.
      this.textureData = {};
      this.textureData.TextureId = TextureId;
      this.getSavedDesignParams();
      this.textureLibrary = {};
      this.toggleLibraryOverlay();
      // UIkit.offcanvas("#LibraryControls").hide();
      this.unsavedChanges = false;
    },
    getSavedDesignParams() {
      // This loads selected item from the library
      log("getSavedDesignParams");
      let ready = false;
      this.isError = false;
      this.designMessage = "";
      const authStore = useAuthStore();
      const user = authStore.user;
      const url = this.baseUrl + "/Textures/GetDesignParams";
      this.textureData.userId = user.Id;
      if (!_.isEmpty(this.textureData.TextureId)) {
        // log("we have a TextureId");
        // this.textureData.TextureId = this.TextureId;
        ready = true;
      }
      if (ready) {
        // log("calling getDesignParams with", this.textureData);
        fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(this.textureData)
        }).then(response => {
          return response.json();
        }).then(getResult => {
          if (getResult.Status === "ok") {
            // Maybe set these when the product is got
            const d = getResult.Data;
            // log(getResult.Data);
            const artP = JSON.parse(d.ArtParams);
            // log("getSavedDesignParams getArtParams", artP);
            const textureP = JSON.parse(d.TextureParams);
            // log("getSavedDesignParams getTextureParams", textureP);
            this.textureData.product_type = this.product_type;
            this.textureData.ItemDesc = "";
            this.textureData.ItemNo = "Select";
            this.textureData.MaterialText = "";
            this.textureData.InchWidth = textureP.width;
            this.textureData.InchHeight = textureP.height;
            this.isError = false;
            this.textureData.TextureId = d.TextureId;
            this.textureData.TextureName = d.TextureName;
            this.textureData.DesignNickname = d.DesignNickname;
            this.textureData.ArtParams = artP;
            this.textureData.TextureParams = textureP;
            this.textureData.Thumbnail = d.Thumbnail;
            this.textureData.FullUrl = d.FullUrl;
            // Because Filename is what we show in cart and is not associated with TextureData
            this.textureData.Filename = d.DesignNickname;
            this.textureData.ImageUrl = d.ImageUrl;
            this.textureData.BumpFile = d.BumpFile;
            this.textureData.TextureImage = d.TextureImage;
            this.textureData.isFinalized = d.isFinalized;
            // this.textureData.isFinalized = false;
            this.textureData.Quantity = 1;
            this.textureData.Type = "Select";
            this.textureData.rollSize = "Select";
            this.designMessage = this.textureData.isFinalized ? 'Design Loaded (Finalized)' : 'Design Loaded (Not Finalized)';
            // log("textureData after return: ", this.textureData);
            this.unsavedChanges = false;
            this.atLoadArtParams();
            this.updatePricing();
          }
          if (getResult.Status === "exception") {
            this.isError = true;
            this.designMessage = getResult.Message;
          }
        });
      }
      this.updatePricing();
    },
    async saveDesignParams() {
      this.saveDesignQueue.art = false;
      this.saveDesignQueue.textureData = false;
      this.saveDesignQueue.image = false;
      this.saveDesignQueue.bump = false;
      this.artFetched = false;
      this.textureDataFetched = false;
      this.imageFetched = false;
      this.bumpFetched = false;
      // log("saveDesignParams");
      // log("ArtParams: ", this.textureData.ArtParams);
      // log("TextureParams: ", this.textureData.TextureParams);
      // log("textureData: ", this.textureData);
      this.isError = false;
      this.designMessage = "";
      const authStore = useAuthStore();
      const user = authStore.user;
      const url = this.baseUrl + "/Textures/SaveDesignParams";
      this.textureData.userId = user.Id;
      // log("Before stringify: ", this.textureData.ArtParams);
      if (!_.isEmpty(this.textureData.ArtParams)) {
        // log("Art Params: ", this.textureData.ArtParams);
        this.textureData.ArtParams = JSON.stringify(this.textureData.ArtParams);
        // log("Art Params: ", this.textureData.ArtParams);
        // this.textureData.ArtParams = this.ArtParams;
      }
      if (!_.isEmpty(this.textureData.TextureParams)) {
        this.textureData.TextureParams = JSON.stringify(this.textureData.TextureParams);
        // log("Texture Params: ", this.textureData.TextureParams);
      }
      // log("After stringify", this.textureData.ArtParams);
      //   this.textureData.TextureParams = JSON.stringify(this.textureData.TextureParams);
      //   this.textureData.ArtParams = JSON.stringify(this.textureData.ArtParams);
      try {
        // log("substring: ", this.textureData.TextureImageB64.substring(22));
        // let temp = {userId: 240367, TextureImageB64: encodedData};
        // log("calling saveDesignParams with call", this.textureData);
        let response = await fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(this.textureData)
        });
        const saveResult = await response.json();
        if (saveResult.Status === "ok") {
          // log("designSaveResult", saveResult);
          const d = saveResult.Data;
          const artP = JSON.parse(d.ArtParams);
          const textureP = JSON.parse(d.TextureParams);
          this.textureData.product_type = this.product_type;
          this.textureData.ItemDesc = d.ChoiceName;
          this.textureData.ItemNo = d.ChoiceValue;
          this.textureData.MaterialText = d.ChoiceName;
          this.textureData.InchWidth = textureP.width;
          this.textureData.InchHeight = textureP.height;
          this.isError = false;
          this.textureData.TextureId = d.TextureId;
          this.textureData.TextureName = d.TextureName;
          this.textureData.DesignNickname = d.DesignNickname;
          this.textureData.ArtParams = artP;
          this.textureData.TextureParams = textureP;
          this.textureData.Thumbnail = d.Thumbnail;
          this.textureData.FullUrl = d.FullUrl;
          this.textureData.Filename = d.Filename;
          this.textureData.ImageUrl = d.ImageUrl;
          this.textureData.BumpFile = d.BumpFile;
          this.textureData.TextureImage = d.TextureImage;
          this.textureData.isFinalized = d.isFinalized;
          this.textureData.Quantity = 1;
          this.textureData.Type = "None";
          this.textureData.rollSize = "sample";
          this.designMessage = this.textureData.isFinalized ? 'Design Loaded (Finalized)' : 'Design Loaded (Not Finalized)';
          // log("textureData after save return: ", this.textureData);
          this.unsavedChanges = false;
          this.isError = false;
          this.atLoadArtParams();
          this.updatePricing();
        }
        if (saveResult.Status === "exception" || saveResult.Status === "fail") {
          this.isError = true;
          this.designMessage = saveResult.Message;
        }
      } catch (e) {
        this.isError = true;
        // log("Exception: ", e);
        this.designMessage = "There was an error saving the design. Please try again.";
      }
      this.saving = false;
      this.finalizing = false;
      // log("what I have to work with: ", this.textureData);
    },
    checkWidthAndResize() {
      console.log("checkWidthandResize");
      const maxWidth = 54;

      if (!this.isSample) {
        const width = (parseFloat(this.textureData.TextureParams.tileWidth) + parseFloat(this.textureData.TextureParams.jointWidthHorizontal)) * parseFloat(this.textureData.TextureParams.columns);
        // log("Texture width values: ", width);
        // should not be hard coded. Change based on if sample or not later and based on roll sizes when they are available
        if (width > maxWidth) {
          // log("too large: ", width);
          this.textureData.OrderNotes = "NOTE: The design will be scaled down to fit standard roll width for this material."
        }

        if (width < maxWidth) {
          // log("too small ", width);
          this.textureData.OrderNotes = "NOTE: The design will be tiled to maximize standard roll width for this material."
        }
      } else {
        this.textureData.OrderNotes = "Sample - A 12 inch section of the design will be printed.";
      }
    },
    confirmOrder() {
      // log("confirmOrder");
      this.orderConfirmed = true;
      this.addToCart();
    },
    cancelOrder() {
      this.textureData.OrderNotes = "";
    },
    goToCart() {
      this.showCartMessage = false;
      router.push({name: 'cart'});
    },
    validateOrder() {
      let ordervalid = true;
      let errors = ["Please correct the following errors: "];
      // log("At Validation: ", this.textureData);
      if (this.textureData.ItemNo === "Select" || this.textureData.ItemNo === "" || this.textureData.ItemNo === undefined) {
        errors.push("Please select a material.");
        ordervalid = false;
      }
      if (this.textureData.Type === "Select" || this.textureData.Type === "" || this.textureData.Type === undefined) {
        errors.push("Please select a finish.");
        ordervalid = false;
      }
      if (this.textureData.rollSize === "Select" || this.textureData.rollSize === "" || this.textureData.rollSize === undefined) {
        errors.push("Please select a roll length.");
        ordervalid = false;
      }

      if (!ordervalid) {
        this.isCartError = true;
        this.cartErrorMessage = errors;
        return false;
      }
      this.isCartError = false;
      this.cartErrorMessage = [];
      // log("Everything is good.");
      return ordervalid;
    },
    addToCart() {
      if (this.validateOrder()) {
        log("addToCart");
        this.showCartMessage = false;
        this.isCartError = false;
        this.cartMessage = "";
        const url = this.baseUrl + "/Store/AddToCart";
        const authStore = useAuthStore();
        const user = authStore.user;
        this.textureData.userId = user.Id;
        let call = [];
        call.push(this.textureData);
        // log("cartCall", call);
        fetch(url, {
          method: "POST",
          headers: authHeader(url),
          body: JSON.stringify(call)
        }).then(responseData => {
          // log("AddToCart responseData: ", responseData);
          return responseData.json();
        }).then((jsonData) => {
          // log("AddToCart jsonData: ", jsonData);
          if (jsonData.Status === "ok") {
            this.orderConfirmed = false;
            this.showCartMessage = true;
            this.cartMessage = jsonData.Message;
            this.textureData.OrderNotes = "";
          } else {
            this.showCartMessage = true;
            this.cartMessage = jsonData.Message;
            this.isCartError = true;
          }
        });
      }
    },
    addProductToCart() {
      console.log("addProductToCart");
      this.showFinalizeMessage = false;
      if (this.textureData.isFinalized) {
        if (this.validateOrder()) {
          this.isSample = false;
          this.checkWidthAndResize();
          if (this.orderConfirmed) {
            this.addToCart();
          }
        }
      } else {
        this.preFinalizeDesign();
      }
    },
    toggleOverlay() {
      if (this.wizard.wizardActive) {
        this.prestageFromWizard();
      } else {
        this.overlayActive = !this.overlayActive;
      }
    },
    toggleLibraryOverlay() {
      this.libraryOverlayActive = !this.libraryOverlayActive;
      const getTextures = this.getTextureLibrary;
      getTextures();
    },
    reloadPage() {
      location.reload();
    },
    updatePricing: _.debounce(function () {
      // log("updatePricing");
      const url = this.baseUrl + "/Store/GetPricing";
      const {user} = useAuthStore();
      let _this = this;
      // clone so we can scrub b64 from the request
      let thisTexture = _.clone(this.textureData);
      thisTexture.userId = user.Id;
      thisTexture.Thumbnail = "";
      let data = []
      data.push(thisTexture);
      // log("pricing data", data);
      fetch(url, {
        method: "POST",
        headers: authHeader(url),
        body: JSON.stringify(data)
      }).then(returnedData => {
        // log("update pricing response", returnedData);
        return returnedData.json();
      }).then((jsondata) => {
        let response = jsondata;
        // log("pricing json response", response);
        if (response.Status === "ok") {
          let pData = response.Data;
          // log("pData", pData);
          if (!_.isEmpty(pData)) {
            _this.textureData.Price = pData[0].Total;
          }
        }
      });
    }, 500),
    getChoiceText(name, available) {
      // log("getChoiceText");
      let returnValue = name;
      if (available == false) {
        returnValue = returnValue + " (Temporarily Unavailable)";
      }
      return returnValue;
    },
    initChoiceOptions(myKey) {
      // Populates the dropdown with substrate options
      // log("initChoiceOptions", myKey);
      // log("initChoiceOptions configdata", this.configData);
      let what = _.filter(this.configData.dtOptionChoices, ['OptionKey', myKey]);
      // log("initChoiceOption", what);
      // console.log(sorted)
      return _.sortBy(what, ["ChoiceSort"])
    },
    async readyToSave() {
      let ready = true;

      if (this.saveDesignQueue.art == false) {
        ready = false;
      }
      if (this.saveDesignQueue.textureData == false) {
        ready = false;
      }
      if (this.saveDesignQueue.image == false) {
        ready = false;
      }
      if (this.saveDesignQueue.bump == false) {
        ready = false;
      }
      // log("Art status: ", this.saveDesignQueue.art);
      // log("TextureData status: ", this.saveDesignQueue.textureData);
      // log("Image status: ", this.saveDesignQueue.image);
      // log("Bump status: ", this.saveDesignQueue.bump);

      // log("readyToSave", ready);
      return ready;
    },
    clearSearch() {
      this.searchText = '';
    }
  },
  computed: {
    warningColor() {
      if (this.textureData.isFinalized) {
        return "uk-alert-muted";
      }
      if (!this.unsavedChanges && !this.textureData.isFinalized) {
        return "uk-text-warning";
      }
      return "uk-text-danger";
    },
    productOptionChoices() {
      // console.log("productOptionChoices");
      let choiceData = {};
      choiceData = _.clone(this.configData.dtOptions);
      // Need to remove product_type
      _.pullAllBy(choiceData, [{
        'OptionKey': 'product_type'
      }], 'OptionKey');
      // log("productOptionChoices", choiceData);
      return choiceData;
    },
    filteredData() {
      const newData = []
      // console.log("Search Text: ", searchText.value);
      if (this.searchText && this.textureLibrary) {
        for (let i = 0; i < this.textureLibrary.length; i++) {
          let item = this.textureLibrary[i];
          // console.log("Item: ", item);
          if (item.DesignNickname.toLowerCase().includes(this.searchText.toLowerCase())) {
            newData.push(item)
          }
        }
      }

      if (newData.length > 0) {
        return newData.sort((a, b) => {
          return a.DesignNickname.localeCompare(b.DesignNickname);
        })
      }

      if (newData.length == 0 && this.searchText) {
        return []
      } else {
        return this.textureLibrary;
      }
    }
  },
  watch: {
    "textureData.DesignNickname":
        function (val) {
          if (val == '') {
            this.showNicknameWarning = true;
          } else {
            this.showNicknameWarning = false;
          }
        },
    textureChoice: function (val) {
      // log("textureChoice: ", val);
    },
    saveDesignQueue: {
      handler(val, oldVal) {
        let canSave = true;
        if (val.art == false) {
          canSave = false;
        }
        if (val.textureData == false) {
          canSave = false;
        }
        if (val.image == false) {
          canSave = false;
        }
        if (val.bump == false) {
          canSave = false;
        }
        // log("canSave art: ", val.art);
        // log("canSave textureData: ", val.textureData);
        // log("canSave image: ", val.image);
        // log("canSave bump: ", val.bump);
        // log("Save Design Queue watcher - canSave: ", canSave);
        if (canSave === true) {
          this.saveDesignParams();
        }
      },
      deep: true
    },
    "textureData.ItemNo":
        function (val) {
          // log("ItemNo/Material Change: ", val);
          this.setConfigData();
        },
    "textureData.rollSize":
        function (val) {
          // log("rollSize Change: ", val);
          // this.setConfigData();
        },
    "textureData.Quantity":
        function (val) {
          // log("Quantity Change: ", val);
          this.setConfigData();
        },
  }
}
</script>
<template>
  <div class="designer-start uk-container uk-container-expand uk-margin-large-top uk-margin-large-bottom">
    <div uk-grid>
      <div class="uk-width-expand uk-panel uk-margin-large-bottom">
        <!-- production version limited to quality images -->
<!--        <iframe id="Arch" class="arch-frame" src="https://architextures.org/create?appid=1077/"></iframe>-->
        <!-- original production version -->
                 <iframe id="Arch" class="arch-frame" src="https://architextures.org/create?appid=1039/"></iframe> 
        <!-- version with bump controls -->
        <!--                <iframe id="Arch" class="arch-frame" src="https://architextures.org/create?appid=1052"></iframe>-->
        <!-- Dynamic version to allow pre-loading from Library if we wanted to-->
        <!--         <iframe id="Arch" class="arch-frame" :src="'https://architextures.org/create/' + artxPatternId + '?appid=1039/'"></iframe>-->
        <div class="reload-btn-container">
          <button class="reload-btn" @click="reloadPage"><span uk-icon="icon: refresh"></span>Reload</button>
        </div>
        <div :class="{'uk-alert-warning': isError}"
             class="design-status-container uk-padding-small uk-position-top-right">
          <span>Design Status:</span>
          <div :class="warningColor" class="design-status uk-inline uk-margin-left uk-text-center">
            {{ designMessage }}
          </div>
        </div>
        <div id="LibraryofDesigns" class="library-btn-container">
          <!-- <button class="library-btn" uk-toggle="target: #LibraryControls" @click="toggleLibraryOverlay"> -->
          <button class="library-btn" @click="toggleLibraryOverlay">
            <span uk-icon="icon: image" class="library-btn-icon uk-icon"></span>
            My Library
          </button>
        </div>

        <!-- Library Overlay -->
        <div class="library-overlay-container">
          <div :class="libraryOverlayActive ? 'library-overlay-expanded' : ''"
               class="library-overlay uk-panel uk-panel-scrollable expand uk-position-relative">
            <div v-if="loading" class="uk-flex uk-flex-column uk-position-center ">
              <div class="uk-align-center" uk-spinner="ratio: 3"></div>
              <div class="saving uk-text-center">Loading</div>
            </div>
            <button class="uk-offcanvas-close" type="button" uk-close v-on:click="toggleLibraryOverlay">
              Close&nbsp;
            </button>
            <div id="LibraryControls uk-height-1-1">
              <div class="LibraryControls">
                <div v-if="isLibraryError" class="uk-margin-bottom">
                  <div class="uk-alert uk-alert-warning">{{ libraryMessage }}</div>
                </div>
                <div id="LibraryItems" class="uk-position-relative uk-height-1-1">
                  <div class="search-container uk-flex uk-flex-top">
                    <Search class="uk-light uk-margin-right uk-height-1-1" v-model="searchText"/>
                    <button @click="clearSearch"
                            class="uk-button project-button uk-button-small uk-background-primary uk-text-underline">
                      Clear Search
                    </button>
                  </div>
                  <div v-if="filteredData.length > 0"
                       class="uk-flex uk-child-width-1-3 uk-child-width-1-5@l expand uk-padding uk-padding-remove-right"
                       uk-grid>
                    <template v-for="d in filteredData" class="uk-height-1-1">
                      <div class="uk-card uk-card-hover uk-padding-small">
                        <div v-on:click="loadDesignFromLib(d.TextureId)" class="">
                          <div class="texture-header uk-flex uk-flex-between">
                            <div class="uk-text" v-if="d.DesignNickname">{{ d.DesignNickname }}</div>
                            <div class="tool-tips">
                              <span v-if="d.isFinalized"
                                    uk-tooltip="title: Design can not be changed.; pos: top-right"
                                    uk-icon="eye">
                              </span>
                              <span v-if="!d.isFinalized"
                                    uk-tooltip="title: Design can be changed.; pos: top-right"
                                    uk-icon="eye-slash">
                              </span>
                            </div>
                          </div>
                          <hr v-if="d.DesignNickname">
                          <!-- <div class="uk-text">{{ d.TextureName }}</div> -->
                          <div class="uk-height-small uk-responsive uk-background-cover" v-bind:data-src="d.Thumbnail"
                               uk-img></div>
                        </div>
                      </div>
                    </template>
                  </div>
                  <div v-if="searchText && filteredData.length == 0">
                    <p>No matching textures found</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End Library Overlay -->

        <!-- Create Order -->
        <div id="CreateandSaveOrder" class="order-btn-container">
          <button class="order-btn" @click="toggleOverlay">
            <span uk-icon="icon: check" class="uk-icon"></span>
            Save / Order
          </button>
        </div>
        <div class="overlay-container">
          <div :class="overlayActive ? 'overlay-expanded' : ''" class="overlay">
            <button class="uk-offcanvas-close" type="button" uk-close v-on:click="toggleOverlay">
              Close&nbsp;
            </button>
            <div id="TextureControls" class="overlay-content-container uk-position-center">
              <!-- saving animation -->
              <div class="saving-outer-container uk-position-relative">
                <div class="saving-container saving" v-if="saving">
                  <div class="" uk-spinner="ratio: 3"></div>
                  <div class="saving uk-text-center">Saving...</div>
                  <p>This may take some time. Please do not close or refresh the browser.</p>
                </div>
                <div>
                </div>
              </div>
              <!-- finalizing animation -->
              <div class="saving-outer-container uk-position-relative">
                <div class="saving-container saving" v-if="finalizing">
                  <div class="" uk-spinner="ratio: 3"></div>
                  <div class="saving uk-text-center">Finalizing...</div>
                </div>
              </div>
              <div class="textureControls uk-flex uk-flex-row uk-flex-between uk-align-center uk-width-1-1 uk-padding">
                <div id="SaveAndRestore"
                     class="uk-flex-1 uk-padding uk-padding-remove-left uk-padding-remove-top uk-align-center">
                  <div class="uk-text-center uk-text-muted uk-margin-bottom">Save and Restore Design
                    Changes
                  </div>
                  <div class="uk-margin-bottom">
                    <div v-if="textureData.Thumbnail" class="uk-height-small uk-width-1-1"
                         v-bind:data-src="textureData.Thumbnail" uk-img></div>
                    <div class="uk-text-bold">Design Name:</div>
                    <span class="uk-text-italic">{{ textureData.TextureName }}</span>
                  </div>
                  <div class="uk-form-controls uk-margin-bottom">
                    <div class="uk-text-bold uk-form-controls">Nickname</div>
                    <input type="text" class="uk-input uk-text-center"
                           v-model="textureData.DesignNickname">
                    <p v-if="this.showNicknameWarning" :class="'uk-alert uk-alert-warning'">{{ nickNameWarning }}</p>
                  </div>
                  <div v-if="!textureData.isFinalized"
                       class="uk-flex uk-flex-between uk-flex-wrap uk-flex-row@l uk-margin-bottom uk-padding uk-padding-remove-top uk-padding-remove-bottom uk-padding-remove-right"
                       uk-grid>
                    <div class="uk-padding-small uk-padding-remove-vertical uk-width-1-2@l">
                      <button class=""
                              :class="{'uk-disabled uk-button-danger': !textureData.DesignNickname, 'uk-button uk-button-primary project-button uk-margin-right uk-width-1-1': !textureData.isFinalized }"
                              v-on:click="saveDesign">
                        <span class="uk-margin-small-right" uk-icon="push"></span>Save
                      </button>
                    </div>
                    <div class="uk-padding-small uk-padding-remove-vertical uk-width-1-2@l">
                      <!-- removed tooltip: uk-tooltip="Restore design to last save." -->
                      <button class="uk-button uk-button-primary project-button uk-width-1-1"
                              v-on:click="restoreDesign">
                        <span class="uk-margin-small-right" uk-icon="pull">&nbsp;</span>Restore
                      </button>
                    </div>
                  </div>
                </div>
                <div class="vertical-line"></div>

                <!-- The Project-->
                <div
                    class="uk-margin-bottom uk-flex-1 uk-flex-center uk-padding uk-padding-remove-right uk-padding-remove-top">
                  <div class="uk-text-center uk-text-muted uk-margin-bottom">Order Product or Samples</div>
                  <div class="">
                    <div id="Ordering" class="uk-margin-bottom">
                      <div class="uk-form-controls uk-flex uk-flex-column uk-flex-between">
                        <div class="texture-order-options-container uk-flex-1 uk-margin-bottom">
                          <label for="orderQty">Order Quantity:</label>
                          <input type="number" min="1" name="orderQty" id="orderQty"
                                 v-model.number="textureData.Quantity" maxlength="3"
                                 class="uk-input uk-align-left input-text uk-margin-remove">
                        </div>
                        <div id="OptionControls" class="">
                          <template v-for="option in productOptionChoices" :key="option.OptionKey">
                            <!--  Select Options -->
                            <div v-bind:id="'col_' + option.OptionKey"
                                 class="texture-order-options-container uk-flex-1 uk-margin-bottom"
                                 v-bind:hidden="!checkOptionAvailable(option.OptionKey)">
                              <label v-bind:for="option.OptionKey">
                                {{ option.OptionName }}
                              </label>
                              <select v-model="textureData[option.OptionKey]" v-on:change="choiceChange"
                                      v-bind:id="option.OptionKey"
                                      v-bind:name="option.OptionKey"
                                      class="texture-dropdown uk-margin-top uk-margin-remove uk-align-left">
                                <option value="Select" disabled> Select</option>
                                <option v-for="choice in initChoiceOptions(option.OptionKey)"
                                        :value="choice.ChoiceValue"
                                        v-bind:disabled="!choice.ChoiceAvailable"
                                        v-bind:class="{ choiceDisabled: !choice.ChoiceAvailable }"
                                        v-text:="getChoiceText(choice.ChoiceName, choice.ChoiceAvailable)">
                                </option>
                              </select>
                            </div>
                          </template>
                        </div>
                        <div v-if="!this.franchise === 'NGS'"
                             class="price-container uk-flex uk-flex-row uk-flex-middle uk-flex-between uk-padding uk-padding-remove-vertical">
                          <label class="uk-text-large uk-text-middle"
                                 for="price">Price:</label>
                          <p>{{ $toCurrency(this.textureData.Price) }}</p>
                        </div>
                      </div>
                    </div>
                    <div v-if="!textureData.OrderNotes" id="SamplesAndProduct "
                         class=" uk-margin-bottom uk-flex uk-flex-between uk-flex-wrap uk-flex-row@l">
                      <div v-if='designMessage == "Not saved"' class="uk-padding-small uk-padding-remove-vertical">
                        <p>Save desgin before ordering.</p>
                      </div>
                      <div v-if='designMessage != "Not saved"'
                           class="uk-padding-small uk-padding-remove-vertical uk-width-1-2@l">
                        <button class="uk-button uk-button-primary project-button uk-align-right@l uk-width-1-1"
                                v-on:click="addProductToCart">
                          <span class="uk-margin-small-right" uk-icon="plus">&nbsp;</span>Order
                        </button>
                      </div>
                    </div>
                    <div v-if="showCartMessage"
                         class="uk-card uk-card-muted uk-padding-small uk-padding-remove-top">
                      <p :class="{'uk-text-success': !isCartError, 'uk-text-warning': isCartError}">
                        {{ cartMessage }}</p>
                      <button class="uk-button uk-button-primary project-button"
                              @click="goToCart">Go to Cart
                      </button>
                    </div>
                    <div v-if="showFinalizeMessage">
                      <div class="uk-card uk-card-muted uk-padding-small uk-padding-remove-top">
                        <p>Before we can place an order, we need to finalize the design. Once the design is finalized,
                          you will not be able to make any changes to it.</p>
                      </div>
                      <button v-if="!textureData.isFinalized"
                              class="uk-button uk-button-small uk-button-primary project-button uk-width-1-1"
                              v-on:click="finalizeDesign"
                              title="Finalize design for order. You can not make changes after design is finalized.">
                        <span class="uk-margin-small-right" uk-icon="file-text"></span>Finalize Design
                      </button>
                    </div>
                    <div v-if="isCartError" class="uk-card uk-card-default uk-text-warning uk-padding">
                      <p v-for="error in cartErrorMessage" class="uk-margin-remove">{{ error }}</p>
                    </div>
                    <div v-if="textureData.OrderNotes"
                         class="uk-card uk-card-default uk-text-warning uk-padding">
                      <p>{{ this.textureData.OrderNotes }}</p>
                      <div class="confirm-container uk-flex uk-flex-row uk-flex-between">
                        <!--                        <button v-if="this.textureData.isFinalized"-->
                        <button class="uk-button uk-button-primary project-button"
                                @click="confirmOrder">Confirm
                        </button>
                        <button class="uk-button uk-button-primary project-button"
                                @click="cancelOrder">Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="isProject" id="OrderDetails" class="">
                    <h4>Order Details</h4>
                  </div>
                  <hr class="horizontal-line">
                  <div class="uk-margin-bottom">
                    <div class="uk-text-center uk-text-muted uk-margin-bottom">Order history and
                      shipping information
                    </div>
                    <div id="OrdersAndShippingLinks"
                         class="uk-flex uk-flex-between uk-flex-wrap uk-flex-row@l">
                      <div class="uk-padding-small uk-padding-remove-vertical uk-width-1-2@l">
                        <router-link :to="{name: 'accountDetails'}"
                                     class="uk-button uk-button-primary project-button uk-margin-right-small uk-margin-bottom uk-width-1-1"
                                     uk-tooltip="Review active and archived orders.">
                          <span class="uk-margin-small-right" uk-icon="icon: user"></span>Orders
                        </router-link>
                      </div>
                      <div class="uk-padding-small uk-padding-remove-vertical uk-width-1-2@l">
                        <router-link :to="{name: 'shippingAddresses'}"
                                     class="uk-button uk-button-primary project-button uk-margin-right-small uk-margin-bottom uk-width-1-1"
                                     uk-tooltip="Manage client shipping addresses.">
                          <span class="uk-margin-small-right" uk-icon="file-text"></span>Shipping
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end overlay test -->
      </div>
    </div>
  </div>
</template>